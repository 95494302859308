import { IconButton, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { API_ENDPOINT, axiosToken } from '../../../../services/api';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import AddNewParent from './AddNewParent';
import MapIcon from '@mui/icons-material/Map';
import MapStudentWithParent from '../components/MapStudentWithParent';
import EditParent from './EditParent';
import { DownloadReports } from '../../../../services/reportsService';

export default function ManageParentsListTable() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [show_map,setShowMap] = useState(false);
    const [par_id,setParId] = useState(null);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    useEffect(() => {  }, [add_std])
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`/admin/manageparents?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getStudentData = () => {
        axiosToken.get(`/admin/manageparents?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/admin/manageparents?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser =(id,value) =>{
        axiosToken.delete('/admin/manageparents?id='+id+'&status='+value)
        .then((res)=>{
            if (res.data.status) {
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })

        .catch((err)=>{console.log(err)})
    }


    useEffect(() => { getStudentData() }, [])
    const downloadReports=()=>{
        let body = {
            url: `${API_ENDPOINT}admin/manageparents?page=1&item=${total}`,
          };
        DownloadReports(body,"parents_reports")
    }
    return (
        <>

            <AddNewParent openDialogProp={add_std} onClose={() => { setAdd_std(false); getStudentData(); }} onCancel={()=>{setAdd_std(false)}} />
            <MapStudentWithParent  openDialogProp={show_map} onClose={setShowMap} par_id={par_id} onCancel={setShowMap} />
            <EditParent openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false); getStudentData();setEditData([]) }} onCancel={()=>{ setEdit_std(false);setEditData([])}}/>
            <div className='student_table_container' id='manage_student_table'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Parents
                        </li>
                    </ol>
                </nav>
                <div className='d-flex justify-content-end align-items-center'>
                    <Button className='student_report_btn' onClick={downloadReports}>Download Reports</Button>
                <div
                    className="gender_btn__cc btn_active add_new_std_student"
                    style={{ maxWidth: '200px' }}
                    onClick={() => {setAdd_std(true)}}
                >
                    <h6>Add New Parent</h6>
                </div>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Parents({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                       
                    </div>
                </div>
                
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Education</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Registered On</TableCell>
                                    {/* <TableCell>Course Enrolled</TableCell> */}
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row?.email}</TableCell>
                                        <TableCell>{row?.education}</TableCell>
                                        <TableCell>{row?.mobile_no}</TableCell>
                                        <TableCell>{row?.registration_date}</TableCell>
                                        {/* <TableCell>ss</TableCell> */}
                                        <TableCell>
                                            <div className='student_action_container'>
                                                <span className='action__img feedback__given'>
                                                    <Tooltip title="Block/UnBlock" arrow>
                                                        <Switch
                                                            {...label}
                                                            defaultChecked={row.is_active} 
                                                            onChange={(e)=>(blockUnBlockUser(row.id,e.target.checked?1:0))}
                                                        />
                                                            
                                                    </Tooltip>
                                                </span>
                                                <span className='action__img' 
                                                    style={{cursor:'pointer'}}
                                                    onClick={()=>{   setParId(row.id);setShowMap(true)  }}
                                                >
                                                <Tooltip title="Map a Student" arrow>
                                                    <MapIcon />
                                                </Tooltip>
                                                </span>
                                                <span className='action__img'>
                                                <Tooltip title="Edit Parent" arrow>
                                                <IconButton><EditIcon   onClick={() => {
                                                    setEditData(row)
                                                    setEdit_std(true)}}/></IconButton></Tooltip>
                                                    {/* <img src={require('../../../assets/icons/feedback_received.png')} /> */}
                                                </span>


                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowPerPage}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
        </>

    )
}
