import { TextField, IconButton, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { API_ENDPOINT, axiosToken } from '../../../../services/api';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import AddNewTeacher from './AddNewTeacher';
import EditTutor from './EditTutor';
import EditIcon from '@mui/icons-material/Edit';
import constant from '../../../../constant/Validation';
import PreviewIcon from '@mui/icons-material/Preview';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ViewTutorProfile from '../ViewTutorProfile';
import { DownloadReports } from '../../../../services/reportsService';

export default function ManageTeacherListTable() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    const [courseDialog, setCourseDialog] = useState(false);
    const [report_id,setreport_id] = useState(false);
    const [report_show,setreport_show]= useState(false);
    const [open, setOpen] = useState(false);
    //useEffect(() => { setOpen(openDialogProp) }, [openDialogProp]);
    useEffect(() => { }, [add_std])
   
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`/admin/managetutors?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getStudentData = () => {
        axiosToken.get(`/admin/managetutors?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/admin/managetutors?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('/admin/managetutors?id=' + id + '&status=' + value)
            .then((res) => {
                if (res.data.status) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
            })
            .catch((err) => { console.log(err) })
    }


    useEffect(() => { getStudentData(); }, [])
    const downloadReports=()=>{
        let body = {
            url: `${API_ENDPOINT}admin/managetutors?page=1&item=${total}`,
          };
        DownloadReports(body,"tutor_reports")
    }
    const getStudentView = (id) => {
        axiosToken
        .post("admin/view_users?id="+id+`&role=2`)
        .then((res) => {
        })
        .catch((err) => {
          console.log(err.response);
        });
      };
    return (
        <>
         <Dialog
                //open={openDialog}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog'
            >
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                     
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                </DialogActions>
                </Dialog>


            <AddNewTeacher openDialogProp={add_std} onClose={() => { setAdd_std(false); getStudentData();  }} onCancel={() => { setAdd_std(false); }} />
            <EditTutor openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false); getStudentData(); setEditData([]) }} onCancel={() => { setEdit_std(false); setEditData([]) }} />
            <ViewTutorProfile openDialogProp={report_show} id={report_id} onClose={()=>{setreport_show(false); getStudentData();}} onCancel={() => { setreport_show(false); }} />
            <div className='student_table_container' id='manage_student_table'>

                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Tutors
                        </li>
                    </ol>
                </nav>
                <div className='d-flex justify-content-end align-items-center'>
                    <Button className='student_report_btn' onClick={downloadReports}>Download Reports</Button>
                <div
                    className="gender_btn__cc btn_active add_new_std_student"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New Tutor</h6>
                </div>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Tutors ({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Email</TableCell>
                              {/*   <TableCell>Mobile</TableCell> */}
                                <TableCell>Registered On</TableCell>
                                <TableCell style={{textAlign:'center'}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                   <TableCell style={{width:"0"}}><span className={row.is_viewed ? 'redDot redDotHidden':'redDot redDotShow'}>🔴</span></TableCell>
                                    <TableCell component="th" scope="row">
                                     {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.profile_pic ? <img src={constant.common.bidBucket + row.profile_pic} className='tutor_img' /> : <img src={require('../../../../assets/img/no_img.jpg')} className='tutor_img' />}
                                    </TableCell>
                                    <TableCell>{row?.email}</TableCell>
                                  {/*   <TableCell>{row?.mobile_no ? row?.mobile_no : 'N/A'}</TableCell> */}
                                    <TableCell>{row?.registration_date}</TableCell>
                                    <TableCell>
                                        <div className='student_action_container'>
                                      
                                        <span style={{ cursor: 'pointer' }} onClick={() => { /* setCourseDialog(true); */ /* setPackageId(row?.id); setTimeout(() => {getPacageList(row?.id);}, 100); */ }}>
                                            <Tooltip title="View Profile" arrow><PreviewIcon onClick={() =>{
                                                  setreport_id(row.id)
                                                  setreport_show(true);
                                                  getStudentView(row.id)
                                            }} /></Tooltip>
                                        </span>
                                 
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />

                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                            <Tooltip title="Edit Tutor" arrow>
                                                <IconButton>
                                                    <EditIcon onClick={() => {
                                                        setEditData(row)
                                                        setEdit_std(true)
                                                    }} />
                                                </IconButton>
                                                </Tooltip>
                                            </span>

                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
