import { useEffect, useState } from 'react';
import { axiosToken } from '../../../../services/api'
import '../DashboardBox/DashboardBox.css'

export default function DasboardBox() {
    const [count, setCount] = useState('');

    useEffect(() => {
        axiosToken.get('tutor/tutor_dashboard_count')
            .then((res) => { setCount(res.data) }).catch((err) => { console.log(err) })
    }, [])

    return (
        <div className="dashboard_box_container">
            <div className="userInfo">
                <h4 className="user_name">Welcome Back, {count.tutor_name} !</h4>
            </div>
            <div className="mt-4 dashboard_box">
                <div className="dashboard_box_content pink">
                    <h6>Courses</h6>
                    <div className="inner_content">
                        <div>
                            <img src={require('../../../../assets/img/edu.png')} className="dashboard__icon white_icon" />
                        </div>
                        <div className="inner_box">
                            <h5>{count?.course?.inprogress_courses}</h5>
                            <p>Inprogress</p>
                        </div>
                          <div className=" vertical_vl"></div>
                        <div className="inner_box">
                            <h5>{count?.course?.published_courses}</h5>
                            <p>Published</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard_box_content orange">
                    <h6>Assignments</h6>
                    <div className="inner_content">
                        <div>
                            <img src={require('../../../../assets/img/assignment.png')} className="dashboard__icon white_icon" />
                        </div>
                        <div className="inner_box">
                            <h5>{count?.assignments?.drafted_assignment}</h5>
                            <p>Yet to Publish</p>
                        </div>
                        <div className=" vertical_vl"></div>
                        <div className="inner_box">
                            <h5>{count?.assignments?.published_assignments}</h5>
                            <p>Published</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard_box_content green">
                    <h6>Assessments</h6>
                    <div className="inner_content">
                        <div>
                            <img src={require('../../../../assets/img/assessments.png')} className="dashboard__icon white_icon" />
                        </div>
                        <div className="inner_box">
                            <h5>{count?.assessment?.drafted_assessments}</h5>
                            <p>Yet to Publish</p>
                        </div>
                        <div className=" vertical_vl"></div>
                        <div className="inner_box">
                            <h5>{count?.assessment?.published_assessments}</h5>
                            <p>Published</p>
                        </div>
                    </div>
                </div>
                <div className="dashboard_box_content violet">
                    <h6>Feedbacks</h6>
                    <div className="inner_content">
                        <div>
                            <img src={require('../../../../assets/img/feedback.png')} className="dashboard__icon white_icon feed_icon" />
                        </div>
                        <div className="inner_box">
                            <h5>{count?.feedback?.received_feedback}</h5>
                            <p>Received</p>
                        </div>
                       
                        <div className=" vertical_vl"></div>
                        <div className="inner_box">
                            <h5>{count?.feedback?.given_feedback}</h5>
                            <p>Given</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
