import { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import "../../pages/Register/Register.css";
import { apiCall, API_ENDPOINT } from "../../services/api";
import { TextField, Button } from "@mui/material/";
import Images from "../../constant/Images";
import constant from "../../constant/Validation.js";
import { GoogleLogin } from "react-google-login";
import { toast } from "react-toastify";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { loading } from "../../store/actions";
import axios from "axios";


export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const [registervalues, setRegistervalues] = useState(data);
  const initialValues = { name: "", email: "", acceptTerms: false };
  useEffect(() => {
    dispatch(loading(false))
  }, [])
  const clientId =
    "246713937533-8sdmv3udnnbjd7khqrdtpug619ve5nnb.apps.googleusercontent.com";
  const RegisterSchema = Yup.object({
    name: Yup.string()
      .required(constant.Register.nameErrMsg)
      .trim()
      .matches(/^[aA-zZ\s]+$/, constant.Register.validatenumErrMsg),
    email: Yup.string()
      .required(constant.Register.emailErrMsg)
      .email(constant.Register.emailErrMail),
    acceptTerms: Yup.bool().oneOf([true], constant.Register.acceptErrMsg),
  });
  const submitLogin = (payload) => {
    localStorage.setItem("name", payload.name);
    localStorage.setItem("email", payload.email);
    //navigate("/otpverify");
  };
  const responseGoogle = (response) => {
    if (response && response.profileObj && response.profileObj.email !== "") {
      let payload = {
        "id": response?.profileObj?.googleId,
        "email": response?.profileObj?.email,
        "socail_type": "google",
        "role_type": "tutor",
        "name": response?.profileObj?.givenName + ' '+response?.profileObj?.familyName

      }
      axios.post(API_ENDPOINT+'admin/socialsignuptutor', payload)
        .then((res) => {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.setItem("user", JSON.stringify(res.data));
          //localStorage.clear();
          setTimeout(() => {
            navigate("/ProfileRegisteration");
          }, 2000);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    };
  }
    const responseFacebook = (response) => {
      let payload = {
        "id": response.id,
        "email": response.email,
        "socail_type": "facebook",
        "role_type": "tutor",
        "name": response.name

      }
      axios.post(API_ENDPOINT+'admin/socialsignuptutor', payload)
        .then((res) => {
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.setItem("user", JSON.stringify(res.data));
          //localStorage.clear();
          setTimeout(() => {
            navigate("/ProfileRegisteration");
          }, 2000);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12 register_bg"></div>
          <div className="col-md-6 col-sm-12">
            <img className="school__logo" src={Images.Logo} alt="img" />
            <div className="register__container mt-5">
              <h4 className="get_started">Register</h4>
              <p className="newUser_list">
                Existing User ? &nbsp;
                <Link to="/login" className="link">
                  Login
                </Link>
              </p>
              <div className="login_form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={RegisterSchema}
                  onSubmit={(values) => {
                    submitLogin(values);
                    localStorage.setItem("email", values.email);
                    const payload = { email: values.email, };
                    apiCall.post("admin/otpemail", payload).then((res) => {
                      if (res.status) {
                        toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                        navigate("/otpverify");
                      }
                    })
                      .catch((error) => {
                        toast.error(error.response.data.detail, { position: toast.POSITION.TOP_RIGHT, });
                      });
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, values, touched, }) => (
                    <>
                      <div className="row no-gutters">
                        <Form>
                          <div className="spacetext">
                            <TextField
                              className="fieldtext"
                              type="text"
                              name="name"
                              label="Name"
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.name}
                              variant="outlined"
                            />
                          </div>
                          <div className="spacetext">
                            <TextField
                              className="fieldtext"
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              name="email"
                              label="Email"
                              value={values.email}
                              variant="outlined"
                            />
                          </div>
                          <div className="checkforgm ml-3">
                            <Field
                              type="checkbox"
                              name="acceptTerms"
                              className={
                                "form-check-input " +
                                (errors.acceptTerms && touched.acceptTerms
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <span className="terms__cond">
                              I agree to the{" "}
                              <span className="term__color">
                                Terms & Conditions
                              </span>
                            </span>
                            <ErrorMessage
                              name="acceptTerms"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <Button
                            id="btnclase"
                            variant="outlined"
                            color="error"
                            type="submit"
                          >
                            Continue with Email
                          </Button>
                          <span className="divider"></span>
                          <div className="social_login">
                            <span title="Login with Google" >
                              <GoogleLogin
                                //autoLoad={false}
                                className="google_btn_logine"
                                clientId={clientId}
                                onSuccess={responseGoogle}
                                //onFailure={responseGoogle}
                                cookiePolicy={"single_host_origin"}
                                isSignedIn={true}
                              //buttonText="Continue with Google"
                              >
                                {" "}
                                <span className="g_textclr">
                                  {" "}
                                  Continue with Google
                                </span>
                              </GoogleLogin></span>
                            <span title=" Log In With Facebook" >

                              <FacebookLogin
                                appId="1298478504066314"
                                //type="button"
                                //autoLoad
                                fields="name,email,picture"
                                callback={responseFacebook}
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    //id="btnlogin"
                                    //type="button"
                                    className="register_fbcolor py-2"
                                  >
                                    <img
                                      className="fbimgcolor"
                                      src={Images.Fbimg}
                                      alt="Facebook"
                                    />

                                    <span className="fb_btn_text">
                                      Continue With Facebook
                                    </span>
                                  </button>
                                )}
                              /></span>
                          </div>
                        </Form>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
