import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function AddGrade({ openDialogProp, onClose,onCancel }) {
    const GradeSchema  = Yup.object({
        grade_name:Yup.string().required(constant.addGrades.grade_name).trim(),
    })
    const initialValues = {
        grade_name:''
    }
    const [open, setOpen] = useState(false);
const [grade, setGrade] = useState([]);

const handleClose = () => {
    onClose(false)
    setOpen(false);
};
useEffect(() => {
    axiosToken.get("course/getcoursebasicdetails").then((res) => {
        setGrade(res.data.grade)
    })
}, [])

useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])

return (
    <div >
        <Dialog
            open={open}
            fullWidth={'md'}
            maxWidth={'md'}
            keepMounted={false}
            aria-describedby="alert-dialog-slide-description"
            className='assignment__dialog add_new_std'
        >
            <DialogTitle>Add New Grade</DialogTitle>
            <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                        <Formik
                                initialValues={initialValues}
                                validationSchema={GradeSchema}
                                onSubmit={(values) => {
                                   axiosToken.post('/course/create_grade',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                        {({ errors, handleChange, values,handleBlur, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                        <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="grade_name"
                                                                label="Grade Name"
                                                                error={Boolean(touched.grade_name && errors.grade_name)}
                                                                helperText={touched.grade_name && errors.grade_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                      </div>
                                                      </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit() }}
                                                    >
                                                        <h6>Add Grade</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false) }} >
                                                        <h6>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
