import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StarRatings from 'react-star-ratings';
import { TextField, } from "@mui/material/";
import { axiosToken } from '../../../services/api';
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
export default function FeedbacktoStudent({ show, id, openDialogProp, onClose, courseid, studentid, row }) {

  /* const [edit, setEdit] = React.useState(false); */

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    onClose(false)
    setOpen(false);
  };
  /* const editValue = (editData) => {
    return setEdit(editData);
  }; */
  const [rate, setRate] = useState(1);
  const navigate = useNavigate();
  useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])

  const initialValues = {
    course_id: courseid,
    student_id: studentid,
    rating: rate,
    remarks: '',
  }


  return (
    <div >
      <Dialog
        open={open}
        keepMounted={false}
        aria-describedby="alert-dialog-slide-description"
        className='assignment__dialog rating__dialog'
      >
        <DialogTitle>Please rate this student</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                axiosToken.post('tutor/tutorsavefeedback', values)
                  .then((res) => {
                    toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                    setTimeout(() => { handleClose(false) }, 500);
                  })
                  .catch((err) => {
                    toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT, });
                  })
              }}
            >
              {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                <>
                  <Form>
                    <><div>
                      <div>
                        <p>Please rate this student out of 5</p>
                        <StarRatings
                          rating={rate}
                          changeRating={(e) => { setRate(e);setFieldValue('rating',e) }}
                          numberOfStars={5}
                          name='ratings'
                          starRatedColor='#e22a4b' />
                      </div >
                      <div className='mt-3'>
                        <TextField
                          className="fieldtext "
                          type="text"
                          name="remarks"
                          label="Feedback"
                          multiline={true}
                          style={{ marginTop: '20px' }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          //  error={Boolean(touched.email && errors.email)}
                          //  helperText={touched.email && errors.email}
                          variant="outlined" />
                      </div>
                    </div>
                      <DialogActions>
                        <div className="gender_btn btn_active" onClick={() => { handleSubmit(); }}>
                          <h6 >Yes</h6>
                        </div>
                        <div className="gender_btn" onClick={() => { handleClose(false); }}>
                          <h6>No</h6>
                        </div>
                      </DialogActions></>
                  </Form>
                </>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

