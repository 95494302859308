import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import Header from '../../../components/Header/Header'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { loading } from '../../../store/actions'
import '../ManageStudents/ManageStudents.css'
import ManageStudentListTable from './components/ManageStudentListTable'



export default function ManageStudentsAdmin() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loading(false))
  }, [])
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='container-fluid px-5'>
        <div className='row'>
          <div className='col-md-12'>
          <ManageStudentListTable />
          </div>
        </div>
      </div>
    </div>
  )
}
