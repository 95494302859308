import { useEffect, useState } from 'react';
import { Jutsu } from 'react-jutsu'
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import Loader from '../../components/loader/loader'
import { axiosToken } from '../../services/api'
import { loading } from '../../store/actions'


import RecordScreen from './RecordScreen'

export default function VideoMeet() {
    const { state } = useLocation();

    const { data } = state;
    const [room, setRoom] = useState('test123')
    const [subject, setSubject] = useState('Riyas Ahamed')
    const [call, setCall] = useState(false)
    const [userInfo, setUserInto] = useState(null);
    const [classClose, setclassClose] = useState(false);
    const [start, setStart] = useState(false)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loading(false))
        axiosToken.get('/tutor/gettutordetails').then((res) => {
            setUserInto(res.data)
            setCall(true)
            //  dispatch(userProfile(res.data))
        }).catch((err) => { console.log(err.response, 'dddd') })
    }, [])
    return (
        <div>
            {call ? <>
                <Jutsu
                    roomName={state?.data}
                    //userName={name}
                    loadingComponent={<Loader />}
                    domain='jitsi.way2smile.uk/'
                    subject={state?.subject}
                    userInfo={{ "displayName": userInfo?.name, 'email': userInfo?.email, 'avatar': userInfo?.profile_pic }}
                    containerStyles={{ width: '100%', height: '90vh' }}
                    onMeetingEnd={() => { setclassClose(true) }}
                    onJitsi={() => { setStart(true) }}
                />
                <RecordScreen RecordStart={start} classEnd={classClose} data={state.class_info} />
            </> : null}

        </div>
    )
}
