import { useSelector } from 'react-redux';
import '../loader/loader.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function Loader() {
    const loading = useSelector(state=>state.reducer.loading);
  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
    open={loading}
   
  >
    <CircularProgress color="inherit" />
  </Backdrop>
   
  )
}
