import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../services/api';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import AddNewPackage from './AddNewPackage';
import PreviewIcon from '@mui/icons-material/Preview';
import ViewPackageClassDetails from './ViewPackageClassDetails';



export default function PackagesList() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [courseDialog, setCourseDialog] = useState(false);
    const [courseData, setCourseData] = useState([]);
    const [packageId, setPackageId] = useState(null);


    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`course/admin_package_list?page=${newPage}&item=${rowPerPage}`)
            .then((res) => { setRows(res.data.data); setTotal(res.data.total_count) })
            .catch((err) => { console.log(err) })
    };

    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`course/admin_package_list?page=${currentPage}&item=${event.target.value}`)
            .then((res) => { setRows(res.data.data); setTotal(res.data.total_count) })
            .catch((err) => { console.log(err) })
    };
    const getPackageData = () => {
        axiosToken.get(`course/admin_package_list?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => { setRows(res.data.data); setTotal(res.data.total_count) })
            .catch((err) => { console.log(err) })
    }
    const inActivePackage = (status, id) => {
        axiosToken.delete(`course/add_package?id=${status}&status=${id}`)
            .then((res) => {
                if (res.data.status) {
                    toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                }
                else {
                    toast.error(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                }
            })
            .catch((err) => { console.log(err) })
    }


    useEffect(() => { getPackageData() }, []);
    const getPacageList = (id) => {
        axiosToken.get(`admin/getpackagelist?package_id=${id? id :packageId}`)
            .then((res) => { setCourseData(res.data);})
    }
    useEffect(()=>{},[courseData])

    return (
        <>
            <AddNewPackage openDialogProp={add_std} onClose={() => { setAdd_std(false); getPackageData(); }} onCancel={() => { setAdd_std(false) }} />
            <ViewPackageClassDetails openDialogProp={courseDialog} data={courseData} packageId={packageId} onClose={() => { setCourseDialog(false); getPackageData(); }} onCancel={setCourseDialog} reopen={() => {getPacageList()}} />
            
            <div className='student_table_container' id='manage_student_table'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Packages
                        </li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New Package</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Packages ({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Package Name</TableCell>
                                <TableCell>Package Description</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Discount</TableCell>
                                <TableCell>Display Text</TableCell>
                                <TableCell>Courses</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, i) => (
                                <TableRow
                                    key={i}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.package_name}
                                    </TableCell>
                                    <TableCell>{row?.package_description}</TableCell>
                                    <TableCell>{row?.package_amount}</TableCell>
                                    <TableCell>{row?.package_discount_precentage}%</TableCell>
                                    <TableCell>{row?.display_text}</TableCell>
                                    <TableCell>
                                        <span style={{ cursor: 'pointer' }} onClick={() => { setCourseDialog(true); setPackageId(row?.id); setTimeout(() => {getPacageList(row?.id);}, 100); }}>
                                            <Tooltip title="View Courses" arrow><PreviewIcon /></Tooltip>
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Active/InActive" arrow>
                                                    <Switch
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (inActivePackage(row.id, e.target.checked ? 1 : 0, row.id))}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
