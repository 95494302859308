
export const isAdmin =() =>{
    
    return JSON.parse(localStorage.getItem('user'))?.role[0]
}
export const dateFormat = (date) => {
    const d = new Date(date);
    return d.getDate() + '-' + d.toString().substr(4, 3) + '-' + d.getFullYear();
}
export const timeFormat = (date) =>{
    const d = new Date(date);
    return d.toLocaleTimeString('en-IN')
}
function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }

  export  function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
export function checkTimeandDate(date,starttime,endtime){
  const d = new Date();
  alert('ok')
  let mont =  d.getMonth()+1
 
  let check =  mont.length == 1?mont+1:mont

   const today = d.getDate() + '/' + check + '/' + d.getFullYear();
   date.map((s)=>{
     if(s === today){
       alert('ues')
     }else{
       alert('no')
     }
   })
  if(date.find(today)){
    alert(today)
  } else{
    alert('not todat')
  }
}