import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { axiosToken } from '../../../services/api';
import constant from '../../../constant/Validation';
import { toast } from "react-toastify";


export default function AddNewPackage({ openDialogProp, onClose,onCancel }) {
    const PackageSchema = Yup.object({
        package_name: Yup.string().required(constant.addPackage.package_name).trim()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        package_amount: Yup.number().required(constant.addPackage.package_amount),
       // package_discount_percentage: Yup.number(constant.addPackage.package_discount_percentage).required().min(0).max(100),
        package_description: Yup.string().required(constant.addPackage.package_description).trim(),
        course_master: Yup.array().min(1,'Please add atleast one course').required(constant.addPackage.course_master),
        duration: Yup.number().typeError('Duration must be a number').required(constant.addPackage.duration),
        display_text: Yup.string().required(constant.addPackage.display_text).trim(),
    })
    const [open, setOpen] = useState(false);
    const handleClose = () => {setTags([]); onClose(false); setOpen(false); };
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    const [showAutoTag, setShowAutoTag] = useState(false);
    const [autoTags, setAutoTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [clsList, setClsList] = useState([]);

    const initialValues = {
        package_name: '',
        package_amount: '',
        package_description: '',
        package_discount_percentage: 0,
        course_master: tags,
        duration: '',
        display_text: '',
    }
    useEffect(()=>{
      
    },[tags])
    const SearchCourse = (e) => {
        axiosToken.get(`admin/listcourseviastatus?name=${e}&status=1&page=1&item=1000`)
            .then((res) => { setAutoTags(res.data.data) })
            .catch((err) => { console.log(err) })
    }

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New Package</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={PackageSchema}
                                onSubmit={(values) => {
                                     axiosToken.post('course/add_package',values)
                                     .then((res)=>{
                                        toast.success(res.data.message, {position: toast.POSITION.TOP_RIGHT,});
                                        handleClose();
                                     })
                                     .catch((err)=>{
                                        toast.error(err.response.data.detail, {   position: toast.POSITION.TOP_RIGHT,});
                                     })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit, setFieldValue }) => (
                                 
                                 <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="package_name"
                                                                label="Package Name"
                                                                error={Boolean(touched.package_name && errors.package_name)}
                                                                helperText={touched.package_name && errors.package_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.package_name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <TextField
                                                                        className="fieldtext"
                                                                        type="text"
                                                                        name="package_amount"
                                                                        label="Package Amount"
                                                                       /*  error={Boolean(touched.package_amount && errors.package_amount)}
                                                                        helperText={touched.package_amount && errors.package_amount} */
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.package_amount}
                                                                        variant="outlined"
                                                                       
                                                                    />
                                                                     {errors.package_amount ? <span className='custom_err'>Please Enter the Valid value</span> : null}
                                                                </div>
                                                                    <div className='col-md-6' style={{ position: 'relative' }}>
                                                                    <TextField
                                                                        className="fieldtext"
                                                                        type="text"
                                                                        name="package_discount_percentage"
                                                                        label="Discount"
                                                                      
                                                                        onBlur={handleBlur}
                                                                        onChange={(e) => { handleChange(e) }}
                                                                        value={values.package_discount_percentage}
                                                                        variant="outlined"
                                                                    />
                                                                       {errors.package_discount_percentage ? <span className='custom_err'>Please Enter the Valid value</span> : null}
                                                                    <span className='percentage'>%</span>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <div className='col-md-12 pack_des'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="package_description"
                                                                label="Package Description"
                                                                error={Boolean(touched.package_description && errors.package_description)}
                                                                helperText={touched.package_description && errors.package_description}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.package_description}
                                                                variant="outlined"
                                                                rows={4}
                                                                multiline={true}

                                                            />

                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        <div className='col-md-6' style={{ position: 'relative' }}>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                autoComplete="off"
                                                                name="course_master"
                                                                label="Add Courses"
                                                                error={Boolean(touched.course_master && errors.course_master)}
                                                                helperText={touched.course_master && errors.course_master}
                                                                onBlur={() => { setTimeout(() => { setShowAutoTag(false) }, 300); }}
                                                                onFocus={() => { setShowAutoTag(true); }}
                                                                onChange={(e) => { SearchCourse(e.target.value) }}
                                                                variant="outlined"
                                                            />
                                                            {showAutoTag ? (
                                                                <div className="autoTags" style={{ width: '100%' }}>
                                                                    {autoTags.map((data, i) => (
                                                                        <h6
                                                                            key={i}
                                                                            onClick={() => {
                                                                                setTags((pre) => [...pre, data]);
                                                                                // setTimeout(() => {
                                                                                //     setFieldValue('course_master', tags.map((d, i) => { return d.id }))
                                                                                // }, 1000);
                                                                            }}
                                                                        style={{cursor:'pointer'}}
                                                                        >
                                                                            {data.course_name} | {data.course_grade}
                                                                        </h6>

                                                                    ))}
                                                                </div>
                                                            ) : null}
                                                            <div className="tags__list d-flex mt-2">
                                                                {tags?.map((res, i) => (
                                                                    <div className="pills" key={i}>
                                                                        <h5>{res.course_name}</h5>
                                                                        <p
                                                                            onClick={() => {
                                                                                tags.splice(i,1);
                                                                                setTags((pre) => [...pre]);
                                                                              
                                                                                   // setFieldValue('course_master', tags.map((d, i) => { return d.id }))
                                                                               
                                                                            }}
                                                                        >
                                                                            <CloseIcon sx={{ fontSize: 15 }} />
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <TextField
                                                                        className="fieldtext"
                                                                        type="text"
                                                                        name="duration"
                                                                        label="Duration"
                                                                        error={Boolean(touched.duration && errors.duration)}
                                                                        helperText={touched.duration && errors.duration}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.duration}
                                                                        variant="outlined"
                                                                    />
                                                                    <span style={{textAlign:'left',fontSize:'14px',display:'block'}}>*In Days</span>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <TextField
                                                                        className="fieldtext"
                                                                        type="text"
                                                                        name="display_text"
                                                                        label="Display Text"
                                                                        error={Boolean(touched.display_text && errors.display_text)}
                                                                        helperText={touched.display_text && errors.display_text}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.display_text}
                                                                        variant="outlined"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}

                                                    >
                                                        <h6 onClick={() => { 
                                                              setFieldValue('course_master', tags.map((d, i) => { return d.id }))
                                                              setTimeout(() => {  handleSubmit();}, 500);
                                                            }}>Add Package</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5">
                                                        <h6 onClick={() => { onCancel(false);setTags([]) }}>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
