import React, { useEffect, useState } from "react";
import "../Courses/Courses.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { axiosToken } from "../../../../services/api";
import ScheduleDialog from "../ScheduleDialog/ScheduleDialog";
import { useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { toast } from "react-toastify";
export default function Courses({ date }) {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => { getCourseData("1"); }, 1000);

  }, []);
  useEffect(() => { getCourseData("1") }, [date])

  const [value, setValue] = React.useState("1");
  const [InprogressData, setProgressData] = useState(null);
  const [publishedData, setPublishedData] = useState(null);
  const [publishedDataBefore, setpublishedDataBefore] = useState(null);
  const [InprogressDataBefore, setProgressDataBefore] = useState(null);
  const [pubShowView, setpubShowView] = useState(true);
  const [InProShowView, setInProShowView] = useState(true);
  const [hide, setHide] = useState(true);
  const [InProghide, setInProgHide] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    getCourseData(newValue);
  };
  const getCourseData = (id) => {
    axiosToken
      .get("tutor/create_course?status_id=" + id + "&date=[" + date + "]")
      .then((res) => {
        if (id == "0") {
         let data = res?.data?.courses?.map((d)=>{
          if(d.percentage >= 75){
            d.color = '#a5cc3f'
          } else if(d.percentage >= 51){
            d.color='#f88214'
          } else{
            d.color='#e22a4b'
          }
          })
          setProgressData(res.data.courses);
     
          //setProgressDataBefore(InprogressData.slice(0, 1))
        } else {
          let data = res?.data?.courses?.map((d)=>{
            if(d.percentage >= 75){
              d.color = '#a5cc3f'
            } else if(d.percentage >= 51){
              d.color='#f88214'
            } else{
              d.color='#e22a4b'
            }
           
          })
          setPublishedData(res.data.courses);
          //  setpublishedDataBefore(publishedData.slice(0,1))
        
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const publishCourse = (id) => {
    let payload = { course_id: id, status: 1 }
    axiosToken.post('/tutor/updatecoursestatus', payload)
      .then((res) => {
        toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
        getCourseData(0)
      })
      .catch((err) => { console.log(err.response.data) })
  }
  const getSingleCourseData = (id,inprogress) => {
    inprogress? 
    axiosToken.get('/tutor/getcoursedetails?course_id=' + id)
    .then((res) => {
      setTimeout(() => {
        navigate('/edit-course', { state: { data: res.data } })
      }, 1000);
    })
    .catch((err) => { console.log(err.response) })
    :
    axiosToken.get('/tutor/updatecoursedetails?course_id=' + id)
      .then((res) => {
        setTimeout(() => {
          navigate('/edit-course', { state: { data: res.data } })
        }, 1000);
      })
      .catch((err) => { console.log(err.response) })
  }


  useEffect(() => {
    setpublishedDataBefore(publishedData?.slice(0, 5))
    publishedData?.length >= 5 ? setHide(false) : setHide(true)
  }, [publishedData])
  useEffect(() => {
    setProgressDataBefore(InprogressData?.slice(0, 5))
    InprogressData?.length >= 5 ? setInProgHide(false) : setInProgHide(true)
  }, [InprogressData])

  return (
    <>
      <div className="list_box courses">
        <TabContext value={value}>
          <div className="list_box_header">
            <h4>Courses</h4>
            <TabList   TabIndicatorProps={{ style: { backgroundColor: "black" } }}onChange={handleChange}>
              <Tab label="Published" className="tabs__colors" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}}value="1" />
              <Tab label="Inprogress" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}}value="0" />
            </TabList>
          </div>
          <div className="tab__body">
            <TabPanel value="0">
              <div className="tabs_table">
                {InprogressData ? (
                  InprogressDataBefore?.map((data, i) => (
                    <div className="table_list" key={i}>
                      <img src={data.course_image} className="course_img" alt="course_img" />
                      <div className="course__info">
                         <h4>{data.course_name}</h4>
                         <h4 style={{ fontSize: '14px', color: '#757575',marginTop:'5px' }}>{data.date_val}</h4></div>
                      <div className="duration" ><span>{data.hours} </span></div>
                      <div className="link">
                        <Tooltip title="Edit Course">
                          <IconButton onClick={() => { getSingleCourseData(data.id,true) }}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Publish Course">
                          <IconButton onClick={() => publishCourse(data.id)}>
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))
                ) : (
                  <h6>No data found</h6>
                )}
              </div>
              {!InProghide ?
                <div className="table__footer">
                  {InProShowView ? <p className="view_all_btn" onClick={() => {
                    setProgressDataBefore(InprogressData);
                    setInProShowView(false)
                  }}>View All</p> :
                    <p className="view_all_btn" onClick={() => {
                      setProgressDataBefore(InprogressData?.slice(0, 4))
                      setInProShowView(true)
                    }}>View Less</p>
                  }
                </div> : null}

            </TabPanel>
            <TabPanel value="1">
              {publishedData ? (
                publishedDataBefore?.map((data, i) => (
                  <div className="table_list" key={i}>
                    <img src={data.course_image} className="course_img" alt="course_img" />
                    <div className="course__info"> <h4 style={{fontSize:'14px'}}>{data.course_name}</h4> <h4 style={{ fontSize: '14px', color: '#757575', marginTop: '5px' }}>{data.date_val}</h4> </div>
                    <div className="duration__percentage">
                      <span>{data.hours} </span>
                      <div className="progress_container">
                        <div className="progress_box">
                          <span className="progress_bar" style={{ width: data.percentage + '%',backgroundColor:data.color }}></span>
                          <span className="per_value" style={{float:'right'}}>{data.percentage + '%'}</span>
                        </div>
                      </div>
                    </div>
                    <div className="view_schedule">
                      <ScheduleDialog data={data} isManageCourse={false} isUpdate={()=>{getCourseData(1)}}/>
                    </div>
                    <div className="link">
                      <Tooltip title="View Students">
                        <IconButton onClick={() => navigate("/manage-students/" + data.id)}>
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Course">
                        <IconButton onClick={() => getSingleCourseData(data.id,false)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                ))
              ) : (
                <h6>No data found</h6>
              )}
              {!hide ?
                <div className="table__footer">
                  {pubShowView ? <p className="view_all_btn" onClick={() => {
                    setpublishedDataBefore(publishedData);
                    setpubShowView(false)
                  }}>View All</p> :
                    <p className="view_all_btn" onClick={() => {
                      setpublishedDataBefore(publishedData?.slice(0, 4))
                      setpubShowView(true)
                    }}>View Less</p>
                  }
                </div>
                : null}

            </TabPanel>
          </div>
        </TabContext>
      </div >
    </>
  );
}
