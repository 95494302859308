
import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { TextField, Button, IconButton } from "@mui/material/";
import { API_ENDPOINT, axiosToken } from '../../../services/api';
import "../../ManageStudents/ManageStudents.css";



export default function ManagePaymentListTable() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
   
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        getStudentData(newPage, rowPerPage)
    };

    const getStudentData = (page, row) => {
        axiosToken.get(`/admin/payment_users?page=${page}&item=${row}`)
            .then((res) => {
                setTotal(res?.data?.total_count)
                setRows(res?.data?.data)
            })
            .catch((err) => { console.log(err) })
    }

    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        getStudentData(currentPage, event.target.value)

    };

    useEffect(() => { getStudentData(currentPage, rowPerPage) }, [])


    return (
        <>

            <div className='student_table_container' id='manage_student_table'>

                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute"> Dashboard </NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Payment History</li>
                    </ol>
                </nav>
           
               
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Payments({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Student Name</TableCell>
                                <TableCell>Package Name</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Payment Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                     {row?.student}
                                    </TableCell>
                                    <TableCell>{row?.package_name}</TableCell>
                                    <TableCell>{row?.amount}</TableCell>
                                    <TableCell>{row?.payment_status}</TableCell>
                                    <TableCell>{row?.payment_date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
