import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function EditLocation({ openDialogProp, onClose,editData,onCancel }) {
    const LocationSchema  = Yup.object({
        branch_location_name:Yup.string().required(constant.editStudent.branch_location_name) .trim()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        academy_branch_city:Yup.string().required(constant.editStudent.academy_branch_city).trim(),
        state:Yup.string().required(constant.editStudent.state),
        country:Yup.string().required(constant.editStudent.country),
    })

    const initialValues = {
        id:'',
        //auth:'',
        academy_branch_city: '',
        branch_location_name: ''
    } 

    const [open, setOpen] = useState(false);
    const [locationData, setLocationData] = useState()
    const [state, setState] = useState([]);
    const [country, setCountry] = useState([]);
    const [city, getCity] = useState([])
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
     useEffect(() => {
        axiosToken.get("/admin/create_academymaster").then((res) => {
            setState(res.data.state)
            setCountry(res.data.country)
            getCity(res.data.city)
        })
    }, []) 
    useEffect(() => { setOpen(openDialogProp);
        setLocationData(editData) 
        initialValues.id = locationData?.id
        initialValues.academy_branch_city = locationData?.city_id
        initialValues.branch_location_name = locationData?.branch_location_name
        initialValues.state = locationData?.state_id
        initialValues.country = locationData?.country_id
        //initialValues.auth = stdData?.auth  
    }, [openDialogProp,locationData])

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Edit Location</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={LocationSchema}
                                onSubmit={(values) => {
                                   axiosToken.put('/admin/create_academymaster',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                                 {({ errors, handleBlur, handleChange, values, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                 <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                       <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="branch_location_name"
                                                                label="Location Name"
                                                                error={Boolean(touched.branch_location_name && errors.branch_location_name)}
                                                                helperText={touched.branch_location_name && errors.branch_location_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.branch_location_name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                        <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select City
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='city'
                                                                    label="Select City"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    defaultValue={values.academy_branch_city}
                                                                    error={Boolean(touched.city && errors.city)}
                                                                    helperText={touched.city && errors.city}
                                                                >
                                                                    {city.map((e, key) => {
                                                                        return (
                                                                            <MenuItem key={key} value={e.id}>
                                                                                {e.city_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    
                                                </div> 
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit()  }}
                                                    >
                                                        <h6>Update Location</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false) }}>
                                                        <h6>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )

    
}