import { useEffect, useState, useRef } from "react";
import {
  FormControl,
  Select,
  InputLabel,
  TextField,
  FormHelperText,
  Alert,
  MenuItem,
} from "@mui/material";
import Header from "../../../components/Header/Header";
import Images from "../../../constant/Images";
import "../CreateAssignment/CreateAssignment.css";
import { axiosToken, s3config } from "../../../services/api";
import * as Yup from "yup";
import constant from "../../../constant/Validation";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-multi-date-picker";
import SelectForm from "../../../components/Forms/select/Select";
import S3 from "react-aws-s3";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";
import { loading } from '../../../store/actions';
import { useDispatch } from 'react-redux';
import Footer from "../../../components/Footer/Footer";

const CreateAssignmentSchema = Yup.object().shape({
  course: Yup.string().required(constant.CreateAssignment.courseErrMsg),
  assignment_score: Yup.string().required('Please Select Score'),
  question: Yup.string().required(constant.CreateAssignment.introErrMsg).trim(),
  assignment_description: Yup.string().required(constant.CreateAssignment.discriptionMsg).trim(),
  blob: Yup.string().required(constant.CreateAssignment.videoSizeErrMsg),
  assignment_due_date: Yup.string().required(constant.CreateAssignment.durationErrMsg),
});

var todayDate = new Date();
const yyyy = todayDate.getFullYear();
let mm = todayDate.getMonth() + 1; // Months start at 0!
let dd = todayDate.getDate();
if (dd < 10) dd = "0" + dd;
if (mm < 10) mm = "0" + mm;
var todayDate = yyyy + "-" + mm + "-" + dd;
export default function EditAssignment() {
  const [course, getCourse] = useState([]);
  const navigation = useNavigate();
  const blob_img = useRef(null);
  const {state} = useLocation();
  const { data } = state;
  useEffect(() => {
    var token = localStorage.getItem("token");
    axiosToken
      .get("/tutor/list_tutor_courses")
      .then((res) => { getCourse(res.data.courses);})
      .catch((err) => {console.log(err);});
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const checkLocation = location.pathname === '/admin/manage-assignments/' ? true : false;
  let config = {
    ...s3config,
    dirName: "assignments/tutor-assignments",
  };

  const initialValues = {
    id :data.id,
    course: data?.course,
    question: data?.question,
    blob: data?.blob,
    //publish_status: 1,
    //blob_name:data?.blob_name,
    assignment_date: data?.assignment_date,
    assignment_due_date: data?.assignment_due_date,
    assignment_score: data?.assignment_score,
    assignment_description: data?.assignment_description,
    publish_status:data?.publish_status

  };
  const ReactS3Client = new S3(config);
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('user'))?.role[0])
  const uploadFile = (file, name, setFieldValue, field_name) => {
    ReactS3Client.uploadFile(file, 'i2global-' +
    Math.floor(new Date().getTime() + new Date().getSeconds() / 2)+'-'+name)
      .then((data) => {setFieldValue(field_name, data.key); })
      .catch((err) => console.log(err));
  };

  const datePickerRef = useRef();
  const dispatch = useDispatch();

  const UpdateAssignment = (data) => {
    dispatch(loading(true))
    axiosToken.put('/tutor/updateassignment', data)
      .then((res) => {
        if (res.data.status) {
          setTimeout(() => {
            dispatch(loading(false))
            navigation(-1)
          }, 500);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
      })
      .catch((err) => { 
        toast.error(err.response.data.detail, {
            position: toast.POSITION.TOP_RIGHT,
          });  
       })
  }


  function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }

  return (
    <>
      <Header />
      <Formik
        initialValues={initialValues}
        validationSchema={CreateAssignmentSchema}
        onSubmit={(values) => {
          UpdateAssignment(values)
        }}
      >
        {({ errors,handleBlur, handleChange, handleSubmit, isSubmitting,isValid,
          values,
          touched,
          setFieldValue,
          setValue,
        }) => (
          <Form>
            <div className="container-fluid px-5 mt-3 mx-3">
              <div className="row ">
                <div className="col-md-5 add_assignment__form">
                <div className="err_container ">
                {errors && (
                  <FormHelperText error>
                    {errors.course && touched.course ? (
                      <Alert variant="filled" severity="error">
                        <p>{errors.course}</p>
                      </Alert>
                    ) : null}
 {errors.assignment_score && touched.assignment_score ? (
                          <Alert variant="filled" severity="error"> <p>{errors.assignment_score}</p></Alert>
                        ) : null}
                    {errors.question && touched.question ? (
                      <Alert variant="filled" severity="error">
                        <p>{errors.question}</p>
                      </Alert>
                    ) : null}
                    {errors.assignment_description &&
                    touched.assignment_description ? (
                      <Alert variant="filled" severity="error">
                        <p>{errors.assignment_description}</p>
                      </Alert>
                    ) : null}
                    {errors.blob && touched.blob ? (
                      <Alert variant="filled" severity="error">
                        <p>{errors.blob}</p>
                      </Alert>
                    ) : null}

                    {errors.assignment_due_date &&
                    touched.assignment_due_date ? (
                      <Alert variant="filled" severity="error">
                        <p>{errors.assignment_due_date}</p>
                      </Alert>
                    ) : null}
                  </FormHelperText>
                )}
              </div>
              
              
                {role == 2 ?  <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                      <li className="breadcrumb-item" onClick={() => navigate('/assignment')} style={{cursor:'pointer'}}>Assignments</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Edit Assignment
                      </li>
                    </ol> 
                   </nav> :null }

                  <h5>Edit Assignment</h5>
                  <div className="form_createassign">
                    <div className="col g-0 two_div_row">
                      <div className="col-md-12 address_field">
                        <div className=" form_field position-relative">
                          <SelectForm
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            dorpdownValue={course}
                            values={values?.course}
                            label="Select Course"
                            name="course"
                            dropwndownValueName="course_name"
                          />
                        </div>
                        {/*  </div> */}
                      </div>

                      <div className="col-md-12 address_field">
                        <div className=" form_field position-relative">
                          <FormControl fullWidth>
                            <InputLabel id="city-label">Score</InputLabel>
                            <Field
                              as={Select}
                              id="score"
                              name="assignment_score"
                              label="Score"
                              value={values.assignment_score}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            >
                              <MenuItem value={100}>100</MenuItem>
                              <MenuItem value={75}>75</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                              <MenuItem value={20}>20</MenuItem>
                            </Field>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form_field position-relative">
                    <TextField
                      name="question"
                      defaultValue={values?.question}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleChange(e);
                      }}
                      //id="outlined-multiline-static"
                      id="outlined-basic"
                      label="Title"
                      variant="outlined"
                    />
                  </div>

                  <div className="about_textarea_assign form_field position-relative">
                    <TextField
                      name="assignment_description"
                      multiline
                      defaultValue={values?.assignment_description}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleChange(e);
                      }}
                      id="outlined-multiline-static"
                      label="Assignment Description"
                      variant="outlined"
                    />
                  </div>
                  <div className=" form_field position-relative">
                    <TextField
                      id="outlined-basic"
                      label="Upload Document"
                      variant="outlined"
                      name="blob"
                      value={values.blob}
                      //onChange={handleSliderImages}
                    />
                    <span
                      className="upload__icones"
                      onClick={() => {
                        blob_img.current.click();
                      }}
                    >
                      <img src={Images.upload_icon} alt="upload" />
                    </span>
                    <input
                      type="file"
                      multiple
                      id="file"
                      accept="application/pdf"
                      ref={blob_img}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (
                          checkValidFile(
                            e.target.files[0],
                            // [".jpeg", ".jpg", "png", ".pdf", ".doc", ".txt"],
                            [".pdf"],
                            20000000
                          )
                        ) {
                          setFieldValue("blob_name", e.target.files[0].name);
                          uploadFile(
                            e.target.files[0],
                            e.target.files[0].name,
                            setFieldValue,
                            "blob"
                          );
                          var output = document.getElementById("output");
                          output.src = URL.createObjectURL(e.target.files[0]);
                          output.onload = function () {
                            URL.revokeObjectURL(output.src); // free memory
                          };
                        } else {
                          toast.error("Please Select PDF file.",{position: toast.POSITION.TOP_RIGHT,});
                        }
                      }}
                    />
                  </div>

                  <div className="form_fieldix position-relative duration_field">
                    <div className="date_picker_multiple">
                      <DatePicker
                        name="assignment_due_date"
                        value={values.assignment_due_date}
                        onChange={(e) => {
                          setFieldValue(
                            "assignment_due_date",
                            e.format()
                          );
                        }}
                        //onChange={setDate}
                        ref={datePickerRef}
                        format="YYYY-MM-DD"
                        minDate={new Date()}
                      />
                      <img
                        src={Images.calendar}
                        className="picker__icon"
                        onClick={() => datePickerRef.current.openCalendar()}
                      />
                      <span className="lable__flot_custom">Duration</span>
                    </div>
                  </div>

                  <div className="submit__draft">
                    <div className="col checkBox">
                      <div className="submit_btn_container">
                        <div
                          className="gender_btn btn_active"
                          style={{maxWidth:'150px'}}
                         
                        >
                          <h6  onClick={() => { 
                            // setFieldValue('publish_status', 0)
                          setTimeout(() => { handleSubmit(); }, 500);
                        }}>Update</h6>
                        </div>
                        <div className="gender_btn" style={{maxWidth:'150px'}}>
                          <h6 onClick={() =>{
                           /*  setFieldValue('publish_status',0);
                            setTimeout(() => {handleSubmit()}, 1000); */
                            navigate(-1)
                          } }>
                            Cancel
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <pre>{JSON.stringify(values)}</pre> */}
          </Form>
        )}
      </Formik>
      <div className="mb-100"></div>
      {role == 2 ? <Footer /> : null } 
    </>
  );
}
