
import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, Button, IconButton } from "@mui/material/";
import { API_ENDPOINT, axiosToken } from '../../../../services/api';
import AddNewStudent from './AddNewStudent';
import EditStudent from './EditStudent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StudentReport from './StudentReports';
import ViewStudentProfile from '../ViewStudentProfile';
import PreviewIcon from '@mui/icons-material/Preview';
import "../ManageStudents.css"
import { DownloadReports } from '../../../../services/reportsService';



export default function ManageStudentListTable() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    const [report_show,setreport_show]= useState(false);
    const [viewShow, setViewShow] = useState(false);
    const [report_id,setreport_id] = useState("");
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        getStudentData(newPage, rowPerPage)
    };
    const getStudentData = (page, row) => {
        axiosToken.get(`/admin/managestudents?page=${page}&item=${row}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        getStudentData(currentPage, event.target.value)

    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('/admin/managestudents?id=' + id + '&status=' + value)
            .then((res) => {
                if (res.data.status) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
            })
            .catch((err) => {
                toast.error(err.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
    }


    useEffect(() => { getStudentData(currentPage, rowPerPage) }, [])
const downloadReports=()=>{
    let body = {
        url: `${API_ENDPOINT}admin/managestudents?page=1&item=${total}`,
      };
    DownloadReports(body,"stduent_reports")
}

const getStudentView = (id) => {
    axiosToken
    .post("admin/view_users?id="+id+`&role=1`)
    .then((res) => {
    })
    .catch((err) => {
      console.log(err.response);
    });
  };

    return (
        <>
            <AddNewStudent openDialogProp={add_std} onClose={() => { setAdd_std(false);  getStudentData(currentPage, rowPerPage) }}  onCancel={()=>{ setAdd_std(false);}}/>
            <EditStudent openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false);setEditData([]);  getStudentData(currentPage, rowPerPage) }} onCancel={()=>{  setEdit_std(false);setEditData([])}} />
          <StudentReport  openDialogProp={report_show} id={report_id} onClose={()=>{setreport_show(false);setreport_id(null)}}  />
           <ViewStudentProfile openDialogProp={viewShow} id={report_id} onClose={()=>{setViewShow(false);getStudentData(currentPage, rowPerPage)}} onCancel={() => { setViewShow(false); }} />

            <div className='student_table_container' id='manage_student_table'>

                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute"> Dashboard </NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Manage Students</li>
                    </ol>
                </nav>
                <div className='d-flex justify-content-end align-items-center'>
                    <Button className='student_report_btn' onClick={downloadReports}>Download Reports</Button>
                    <div
                    className="gender_btn__cc btn_active add_new_std_student"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New Student</h6>
                </div>
                </div>
               
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Students({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Registered On</TableCell>
                                {/* <TableCell>Mobile</TableCell> */}
                                {/* <TableCell>Gender</TableCell> */}
                                {/* <TableCell>Course Enrolled</TableCell> */}
                                <TableCell style={{minWidth:'200px'}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{width:"0"}}><span className={row.is_viewed ? 'redDot redDotHidden':'redDot redDotShow'}>🔴</span></TableCell>
                                    <TableCell component="th" scope="row">
                                     {row.name}
                                    </TableCell>
                                    <TableCell>{row?.email}</TableCell>
                                    <TableCell>{row?.grade}</TableCell>
                                    <TableCell>{row?.registered_date}</TableCell>
                                    {/* <TableCell>{row?.mobile}</TableCell> */}
                                    {/* <TableCell>{row?.gender}</TableCell> */}
                                    <TableCell>
                                        <div className='student_action_container'>
                                        <span style={{ cursor: 'pointer' }} onClick={() => { /* setCourseDialog(true); */ /* setPackageId(row?.id); setTimeout(() => {getPacageList(row?.id);}, 100); */ }}>
                                            <Tooltip title="View Profile" arrow><PreviewIcon onClick={() =>{
                                                  setreport_id(row.id)
                                                  setViewShow(true);
                                                  getStudentView(row.id)
                                            }} /></Tooltip>
                                        </span>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                                <Tooltip title="Edit Student">
                                                    <IconButton>
                                                        <EditIcon onClick={() => {
                                                            setEditData(row)
                                                            setEdit_std(true)
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="View Report">
                                                    <IconButton>
                                                        <AssessmentIcon onClick={() => {
                                                            setreport_id(row.id)
                                                            setreport_show(true)
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
