import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import constant from '../../../../constant/Validation';
import ArticleIcon from '@mui/icons-material/Article';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { axiosToken } from '../../../../services/api';


export default function StudentClassList({ data, trigger, StudentID, attendanceData }) {
    return (
        <div>

            <h6 style={{ textAlign: 'left',fontFamily:'sans-serif',color:'#0b0b0b',fontWeight:700 }}>Course Details</h6>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Course Image</TableCell>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Classes</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Author</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <img src={constant.common.bidBucket + row.course_image} style={{ width: '100px', height: '100px', borderRadius: '50%' }} />

                                </TableCell>
                                <TableCell>{row?.course_name}</TableCell>
                                <TableCell>{row?.class_count}</TableCell>
                                <TableCell>{row?.description}</TableCell>
                                <TableCell>{row?.author}</TableCell>

                                <TableCell>
                                    <div className='student_action_container'>
                                        <span className='action__img feedback__given'>
                                            <Tooltip title="Student Attendance"
                                                onClick={() => {
                                                    axiosToken.get(`admin/get_attendance_details?student_id=${StudentID}&course_id=${row.id}`)
                                                        .then((res) => {
                                                            attendanceData(res.data)
                                                            trigger(true)
                                                        })
                                                        .catch((err) => { console.log(err.response) })       
                                                }}>
                                                <IconButton>
                                                    <ArticleIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data == ''? <h5 style={{textAlign:'center',width:'100%'}}>No Data Found</h5>:null }
            </TableContainer>
            {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
        </div>
    )
}
