import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import Header from '../../../components/Header/Header'
import Sidebar from '../../../components/Sidebar/Sidebar'
import { loading } from '../../../store/actions'
import PackagesList from '../ManagePackage/PackagesList';
import '../ManagePackage/ManagePackage.css';

export default function ManagePackageAdmin() {
    const dispatch = useDispatch()
    useEffect(()=>{dispatch(loading(false))},[])
  return (
    <div>
        <Header />
        <Sidebar />
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12'>
                    <PackagesList />
                </div>
            </div>
        </div>
    </div>
  )
}
