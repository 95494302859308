import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField } from "@mui/material/";
import { axiosToken } from '../../../services/api';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';

export default function ViewPackageClassDetails({ openDialogProp, onClose, data, packageId, onCancel, reopen }) {
    const [open, setOpen] = useState(openDialogProp);
    const [showAutoTag, setShowAutoTag] = useState(false);
    const [autoTags, setAutoTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [tempTag, setTempTag] = useState([]);
    const [showAddNew, setShowAddNew] = useState(false);
    const [dltCnf, setDltCnf] = useState(false);
    const [dltId, setDltId] = useState(null);

    const initialValues = {
        course_master:''
    }
    const handleClose = () => { onClose(false); setDltCnf(false); setOpen(false); setShowAddNew(false); setTags([]) };

    const removePackageData = (id) => {

        axiosToken.delete(`course/admin_package_list?package_id=${packageId}&course_id=${id}`)
            .then((res) => {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                setDltCnf(false)
                reopen(true)
            })
            .catch((err) => { console.log(err) })
    }
    const addNewPackage = () => {
        let course_ID = tags.map((d) => { return d.id })
        let payload = { "package_master": packageId, "course_master": course_ID }
        axiosToken.post('course/map_packagecourse', payload)
            .then((res) => {
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                setShowAddNew(false); setTempTag([]); setTags([]);
                reopen(true)
            })
            .catch((err) => {
                toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT, });
            })
    }
    const SearchCourse = (e) => {
        axiosToken.get(`admin/listcourseviastatus?name=${e}&status=1&page=1&item=1000`)
            .then((res) => { setAutoTags(res.data.data) })
            .catch((err) => { console.log(err) })
    }

    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    useEffect(()=>{},[dltCnf])


    return (
        <>
            <Dialog open={open} fullWidth={'md'} maxWidth={'md'} keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <div style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => {
                    showAddNew ? setShowAddNew(false) : onCancel(false); setDltCnf(false); setTempTag([]);
                }}>
                    <Tooltip title={'Close'}>
                        <IconButton><CloseIcon /></IconButton>
                    </Tooltip>
                </div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className='my_childs_list'>

                            {showAddNew && dltCnf == false ?
                                <div className='add_new_pack_cont'>
                                    <h4 className='add_cour_title'>Add New Course</h4>
                                    <div className='col-md-12' style={{ position: 'relative' }}>
                                        <div className='search_cont'>
                                           {/*  <form> */}
                                            <TextField
                                                className="fieldtext"
                                                type="text"
                                                autoComplete="off"
                                                name="course_master"
                                                //error={Boolean(touched.course_master && errors.course_master)}
                                                //helperText={touched.course_master && errors.course_master}
                                                label="Add Courses"
                                                onBlur={() => { setTimeout(() => { setShowAutoTag(false) }, 300); }}
                                                onFocus={() => { setShowAutoTag(true); }}
                                                onChange={(e) => { SearchCourse(e.target.value) }}
                                                variant="outlined"
                                            />{/* </form> */}
                                        </div>
                                        {showAutoTag ? (
                                            <div className="autoTags" style={{ width: '100%' }}>
                                                {autoTags.map((data, i) => (
                                                    <h6
                                                        key={i}
                                                        onClick={() => {
                                                            setTags((pre) => [...pre, data]);
                                                            setTimeout(() => {
                                                                setTempTag(tags.map((d, i) => { return d.id }))
                                                            }, 1000);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {data.course_name} | {data.course_grade}
                                                    </h6>

                                                ))}
                                            </div>
                                        ) : null}
                                        <div className="tags__list d-flex mt-2">
                                            {tags?.map((res, i) => (
                                                <div className="pills" key={i}>
                                                    <h5>{res.course_name}</h5>
                                                    <p
                                                        onClick={() => {
                                                            tags.splice(i, 1);
                                                            setTags((pre) => [...pre]);
                                                            setTimeout(() => {
                                                                setTempTag(tags.map((d, i) => { return d.id }))
                                                            }, 1000);
                                                        }}
                                                    >
                                                        <CloseIcon sx={{ fontSize: 15 }} />
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='add_btn__cont d-flex justify-content-center'>

                                        <h6 className='gender_btn btn_active text-white' onClick={addNewPackage}>Update</h6>
                                        <h6 className='gender_btn' onClick={() => { setShowAddNew(false); setTempTag([]); setTags([]); }}>Cancel</h6>
                                    </div>
                                </div>
                                :
                                <>
                                    <h5 className='my_child_title'>Package Course List</h5>
                                    <h6 className='add_new_course gender_btn__cc btn_active' onClick={() => { setShowAddNew(true) }}>Add New Course</h6>
                                    <div className='child_list child_list_header '>
                                        <h4 className='child_text pack_head child_name'>Course Name</h4>
                                        <h4 className='child_text pack_head child_name'>Description</h4>
                                        <h4 className='child_text pack_head'>Class</h4>
                                        <h4 className='child_text pack_head'>Action</h4>
                                    </div>
                                    <div className='child_list_body package__list'>
                                        {data?.map((std, i) => (
                                            <div className='child_list'>
                                                <h4 className='child_text pack_text class_name_pack'>
                                                    <img src={std.course_image} className='course__img' />
                                                    <span>{std.course_name}</span>
                                                </h4>
                                                <h4 className='child_text pack_text'>{std.description}</h4>
                                                <h4 className='child_text pack_text text-center'>{std.class_count}</h4>
                                                <Tooltip title={'Remove Course'}>
                                                    <h4 className='child_text pack_text text-center'
                                                        onClick={() => { setDltCnf(true); setDltId(std.id); }}
                                                    >
                                                        { dltId != std?.id? <IconButton><CloseIcon /></IconButton>:null}
                                                        {dltCnf && dltId == std?.id  ?
                                                            <div className='add_new_pack_cont'>
                                                                {/* <h6 className='add_cour_title' style={{marginBottom:0}}>Remove Course</h6> */}
                                                                <div className='col-md-12' style={{ position: 'relative' }}>
                                                                    <p>Are You sure want to delete this course?</p>
                                                                    <div className='add_btn__cont d-flex justify-content-center'>
                                                                        <h6 className='gender_btn btn_active text-white' onClick={() => { removePackageData(dltId) }}>Yes</h6>
                                                                        <h6 className='gender_btn' onClick={() => {setTimeout(() => {
                                                                             setDltCnf(false);
                                                                             setDltId(null)
                                                                        }, 100); }}>No</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}
                                                    </h4>
                                                </Tooltip>

                                            </div>
                                        ))}
                                    </div>
                                </>
                            }
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
