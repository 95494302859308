import { useEffect, useState, useRef } from 'react';
import { TextField, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../../services/api';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
//import AddNewTeacher from './AddNewTeacher';
//import EditTutor from './EditTutor';
import EditIcon from '@mui/icons-material/Edit';

export default function ManageQuizListTable() {

    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [add_std, setAdd_std] = useState(false);
    const [editData, setEditData] = useState([])
    const [edit_std, setEdit_std] = useState(false);
    const [publish_status,setPublish_status] = useState();
    const searchRef = useRef();

    useEffect(() => { }, [add_std])
    useEffect(() => { getQuizData() }, [])
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        axiosToken.get(`/admin/quiz_set?page=${newPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const getQuizData = () => {
        axiosToken.get(`/admin/quiz_set?page=${currentPage}&item=${rowPerPage}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        axiosToken.get(`/admin/quiz_set?page=${currentPage}&item=${event.target.value}`)
            .then((res) => {
                setTotal(res.data.total_count)
                setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlockUser = (id, value) => {
        axiosToken.delete('/admin/quiz_set?id=' + id + '&status=' + value)
            .then((res) => {
                if(res.data.status){
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
            })
            .catch((err) => { console.log(err) })
    }
    const approveQuiz = (id) => {
        axiosToken.post('admin/approveassignment', { assignment_id: [id] })
            .then((res) => {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getQuizData();
            })
            .catch((err) => { console.log(err) })
    }
    return (
        <>
            {/*  <AddNewQuiz openDialogProp={add_std} onClose={() => { setAdd_std(false); getQuizData(); }} />
            <EditQuiz openDialogProp={edit_std} editData={editData} onClose={() => { setEdit_std(false); getQuizData(); }} /> */}
            <div className='student_table_container' id='manage_student_table'>

                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">
                                Dashboard
                            </NavLink>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            Manage Quizzes 
                        </li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6 onClick={() => navigate('/manage-quiz/create-quiz')}>Add New Quiz</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>Quizzes ({total}) </h6>
                    </div>
                    <div className='right__side student_search'>
                    <h6 className={publish_status == 1 ? 'gender_btn btn_active text-white' : 'gender_btn'} onClick={() => { navigate('/admin/manage-courses/');searchRef.current.reset() }}>Approved</h6>
                        <h6 className={publish_status == 0 ? 'gender_btn btn_active text-white' : 'gender_btn'} onClick={() => { navigate ('/admin/manage-courses/pending');searchRef.current.reset() }}>Pending</h6>
                        <TextField
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"
                        />
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Course Name</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Created By</TableCell>
                                {/*   <TableCell>Added by</TableCell> */}
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.course_name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.duration}

                                    </TableCell>
                                    <TableCell>{row?.date_value}</TableCell>
                                    <TableCell>{row?.is_added_by}</TableCell>
                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Block/UnBlock" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlockUser(row.id, e.target.checked ? 1 : 0))}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                             <Tooltip title="Edit Quiz" arrow>
                                                <IconButton>
                                                    <EditIcon
                                                    onClick={() => {
                                                        axiosToken.get('tutor/updatequizset?id=' + row.id)
                                                            .then((res) => {
                                                                navigate('/manage-quiz/edit-quiz', { state: { data: res.data[0] } }
                                                                )
                                                            })
                                                        setEditData(row.id)
                                                        setEdit_std(true)
                                                    }} />
                                                </IconButton></Tooltip> 
                                         {/*         <TableCell>
                                        {publish_status == 0 ? <Tooltip title="Approve This Quiz"
                                            onClick={() => { approveQuiz(row?.id) }}
                                        >
                                            <CheckIcon style={{ cursor: 'pointer' }} />
                                        </Tooltip> : null}
                                        <Tooltip title="Edit Quiz" arrow>
                                        <IconButton><EditIcon onClick={() => {

getQuizData(row.id)

                                        }} /></IconButton></Tooltip>
                                    </TableCell> */}
                                            </span>

                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
