import { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "../OTPVerify/OTPVerify.css";
import OTPInput from "otp-input-react";
import { Button } from "@mui/material";
import Images from "../../constant/Images";
import { apiCall } from "../../services/api";
import { toast } from "react-toastify";

const OTPSchema = Yup.object().shape({otp: Yup.number(),});

function OTPVerify() {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [otp_status, setOtp_status] = useState(false)
  const Ref = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [showResend,setShowResend] = useState(false);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {total, minutes,seconds, };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setShowResend(false)
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) + ":" +(seconds > 9 ? seconds : "0" + seconds)
      );
    } else {setShowResend(true)}
  };

  const clearTimer = (e) => {
    setTimer("00:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {startTimer(e);}, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const ResendOTP = () => {
    onClickReset();
    var Email = localStorage.getItem("email");
    const payload = { email: Email,role_type:'tutor' };
    apiCall.post("admin/otpemail", payload).then((res) => {
      toast.success("OTP resended Successfully.", {  position: toast.POSITION.TOP_RIGHT, })
        .catch(({ request }) => {
          toast.error(JSON.parse(request.response).message, { position: toast.POSITION.TOP_RIGHT,});
        });
    });
  };

  useEffect(() => { clearTimer(getDeadTime());}, []);
  const onClickReset = () => {  clearTimer(getDeadTime());};

  return (
    <>
      <div className="containere">
        <img src={Images.Bg} alt="black" className="bgimgg" />
        <div className="centered">
          <Formik
            initialValues={{ otp_no: OTP }}
          //  validationSchema={OTPSchema}
            onSubmit={(values) => {
              if (OTP.length == 4) {
                const payload = {
                  otp_no: OTP,
                  email: localStorage.getItem("email"),
                };

                apiCall
                  .post("admin/otpverify", payload)
                  .then((res) => {
                    if (res.data.status) {
                      toast.success("OTP verify Successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      navigate("/create-password");
                    }
                  })
                  .catch((err) => {
                    toast.error("Invalid OTP Number", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  });
              }
              else {
                setOtp_status(true)
             
              }
            }}
          >
            {({ handleSubmit }) => (
              <div className="card verifyotp">
                <Form>
                  <div className="verifycard">
                    <h4 className="mobile">Continue with Email</h4>
                    <a href="/register" className="close_forgoticon"></a>
                  </div>
                  <p className="mobile">
                    we have sent you <b>One Time Password</b> to your email
                    Please Enter OTP
                  </p>
                  <h6 className="mobile ">{timer}</h6>

                 <OTPInput
                    className="validotp"
                    name="otp"
                    OTPLength={4}
                    otpType="number"
                    onChange={setOTP}
                    value={OTP}
                    autoFocus
                    disabled={false}
                  /> 
                   {otp_status ?<p>Please enter OTP</p> :null}
                  <div className="btnverify">
                    <Button
                      type="submit"
                     
                      className="btn btn-danger"
                      style={{
                        width: "150px",
                        background: "rgba(222,62,90,255)",
                        color: "white",
                      }}
                    >
                      Verify OTP
                    </Button>
                    {showResend ? <Button onClick={ResendOTP}  variant="contained" type="button"
                      className="btn btn-light"
                      style={{ backgroundColor: "rgba(255,255,255,255)", color: "red", }} >
                      Resend OTP
                    </Button> :null}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default OTPVerify;
