import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { axiosToken } from '../../../services/api';
import { loading } from '../../../store/actions';
import '../Dashboard/AdminDashboard.css';


export default function AdminDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [count,setCount] = useState(null);
    const getDhasboardData = () =>{
        axiosToken.get('admin/admindashboard')
        .then((res)=>{ setCount(res.data)})
        .catch((err)=>{console.log(err)})
    }
    useEffect(()=>{
        getDhasboardData()
        dispatch(loading(false))
    },[])
  return (
    <div>
        <Header />
        <Sidebar />
        <div className='container-fluid'>
            <div className='row'>
            <div className="dashboard_box_container">
            <div className="dashboard_box admin_dashboard">
                <div className="dashboard_box_content pink" onClick={()=>navigate('/admin/manage-courses')}>
                    <h6 className='text-center'>Total Courses</h6>
                    <div className="inner_content">
                        {/* <div>
                            <img src={Images.edu_icon} className="dashboard__icon" />
                        </div> */}
                        <div className="inner_box d-block w-100">
                            <h5 className='admin_dash_count'>{count?.courses}</h5>
                            
                        </div>
                        {/* <div className="inner_box">
                            <h5>10</h5>
                            <p>Published</p>
                        </div> */}
                    </div>
                </div>
                <div className="dashboard_box_content blue" onClick={()=>navigate('/admin/manage-students')}>
                    <h6 className='text-center'>Total Students</h6>
                    <div className="inner_content">
                        {/* <div>
                            <img src={Images.edu_icon} className="dashboard__icon" />
                        </div> */}
                        <div className="inner_box d-block w-100">
                        <h5 className='admin_dash_count'>{count?.student}</h5>
                            {/* <p>Yet to Publish</p> */}
                        </div>
                        {/* <div className="inner_box">
                        <h5>10</h5>
                            <p>Published</p>
                        </div> */}
                    </div>
                </div>
                <div className="dashboard_box_content green" onClick={()=>navigate('/admin/manage-parents')}>
                    <h6 className='text-center'>Total Parents</h6>
                    <div className="inner_content">
                        {/* <div>
                            <img src={Images.edu_icon} className="dashboard__icon" />
                        </div> */}
                        <div className="inner_box d-block w-100">
                            <h5 className='admin_dash_count'>{count?.parent}</h5>
                            {/* <p>Yet to Publish</p> */}
                        </div>
                        {/* <div className="inner_box">
                            <h5>0</h5>
                            <p>Published</p>
                        </div> */}
                    </div>
                </div>
                <div className="dashboard_box_content violet" onClick={()=>navigate('/admin/manage-teachers')}>
                    <h6 className='text-center'>Total Tutors</h6>
                    <div className="inner_content">
                        {/* <div>
                            <img src={Images.edu_icon} className="dashboard__icon" />
                        </div> */}
                        <div className="inner_box d-block w-100">
                            <h5 className='admin_dash_count'>{count?.tutor}</h5>
                            {/* <p>Received</p> */}
                        </div>
                        {/* <div className="inner_box">
                            <h5>0</h5>
                            <p>Given</p>
                        </div> */}
                    </div>
                </div>

                <div className="dashboard_box_content orange" onClick={()=>navigate('/admin/manage-courses/pending')}>
                    <h6 className='text-center'>Pending Courses</h6>
                    <div className="inner_content">
                        {/* <div>
                            <img src={Images.edu_icon} className="dashboard__icon" />
                        </div> */}
                        <div className="inner_box d-block w-100">
                            <h5 className='admin_dash_count'>{count?.pending_classes}</h5>
                            {/* <p>Received</p> */}
                        </div>
                        {/* <div className="inner_box">
                            <h5>0</h5>
                            <p>Given</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
    </div>
  )
}
