import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../../components/Header/Header';
import { loading } from '../../../store/actions';
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import SelectForm from "../../../components/Forms/select/Select";
import Checkbox from '@mui/material/Checkbox';
import './../CreateQuiz/CreateQuiz.css';
import constant from "../../../constant/Validation";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import { axiosToken } from '../../../services/api';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { isAdmin } from '../../../helpers/utils';
import Footer from '../../../components/Footer/Footer';



const QuizSchema = Yup.object().shape({
  quiz_name: Yup.string().required(constant.CreateQuiz.quiz_name).trim(),
  course_id: Yup.string().required(constant.CreateQuiz.course_id),
  duration: Yup.string().required(constant.CreateQuiz.duration),
  questions: Yup.array(
    Yup.object().shape({
      question: Yup.string().required(constant.CreateQuiz.question).trim(),
      option1: Yup.string().required(constant.CreateQuiz.option1).trim(),
      option2: Yup.string().required(constant.CreateQuiz.option2).trim(),
      option3: Yup.string().required(constant.CreateQuiz.option3).trim(),
      option4: Yup.string().required(constant.CreateQuiz.option4).trim(),
      answer: Yup.string().nullable().required(constant.CreateQuiz.answer)
    })
  ),
});



const durationData = [
  { name: '10 Min', id: 10 },
  { name: '20 Min', id: 20 },
  { name: '30 Min', id: 30 },
  { name: '45 Min', id: 45 },
  { name: '60 Min', id: 60 }
]

const label = { inputProps: { 'aria-label': 'Quiz Answer' } };

export default function CreateQuiz() {
  const [course, setCourse] = useState([]);
  const navigation = useNavigate();
  const [role, setRole] = useState(JSON.parse(localStorage.getItem('user'))?.role[0])
  const initialValues = {
    quiz_name: '',
    course_id: '',
    duration: '',
    publish_status: 1,
    questions: [{
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      answer: null
    }
    ]
  }
  const newQues = { question: '', option1: '', option2: '', option3: '', option4: '', answer: null }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createQuiz = (data) => {
    dispatch(loading(true))
    axiosToken.post('/tutor/tutorcreatequiz', data)
      .then((res) => {
        if (res.data.status) {
          setTimeout(() => {
            dispatch(loading(false))
            navigation(-1)
          }, 500);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });

        }
      })
      .catch((err) => {

        toast.error(err.response.data.detail, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
  }
  const getCourse = () => {
    axiosToken.get('tutor/list_tutor_courses')
      .then((res) => { setCourse(res.data.courses) })
      .catch((err) => { console.log(err) })
  }
  useEffect(() => {
    (
      dispatch(loading(false)))
    getCourse();
  }, [])
  return (
    <div>
      <Header />
    {/*   <Sidebar /> */}
      <div className='container mx-5 mt-3'>
        <div className='row'>
          <div className='col-md-7'>
          {role == 2 ?  <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                      <li className="breadcrumb-item" onClick={() => navigate('/manage-quiz')} style={{cursor:'pointer'}}>Quiz</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Add New Quiz
                      </li>
                    </ol>
                  </nav> :null }

          {/*   <div className='breadcrumb'>
              <span>Quiz / </span>
              <span>Add New Quiz  </span>
            </div> */}
            <div className='create_quiz_container'>
              <h5 className='quiz__header'>Create Quiz</h5>

              {/* QUIZ FORM START HERE  */}
              <Formik
                initialValues={initialValues}
                validationSchema={QuizSchema}
                onSubmit={(values) => {
                  createQuiz(values)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                  touched,
                  setFieldValue,
                }) => (
                  <Form>
                    <div className='row quiz__form'>
                      {/* ERROR MESSAGE HERE */}

                      <div className="err_container col-md-12">
                        {errors && (
                          <FormHelperText error>
                            {errors.quiz_name && touched.quiz_name ? (
                              <Alert variant="filled" severity="error">
                                <p>{errors.quiz_name}</p>
                              </Alert>
                            ) : null}
                            {errors.course_id && touched.course_id ? (
                              <Alert variant="filled" severity="error">
                                <p>{errors.course_id}</p>
                              </Alert>
                            ) : null}
                            {errors.duration && touched.duration ? (
                              <Alert variant="filled" severity="error">
                                <p>{errors.duration}</p>
                              </Alert>
                            ) : null}
                          </FormHelperText>
                        )}
                      </div>

                      <div className='col-md-4 form_field'>
                        <SelectForm
                          values={values.course_id}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          dorpdownValue={course}
                          label="Select Course"
                          name="course_id"
                          dropwndownValueName="course_name"
                        />
                      </div>
                      <div className='col-md-4 form_field quiz_name'>
                        <TextField
                          id="outlined-multiline-static"
                          label="Quiz Name"
                          variant="outlined"
                          name="quiz_name"
                          onBlur={(e) => {
                            handleBlur(e);
                            handleChange(e);
                          }}
                        />
                      </div>
                      <div className='col-md-4 form_field quiz_name'>
                        <SelectForm
                          values={values.duration}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          dorpdownValue={durationData}
                          label="Select Duration"
                          name="duration"
                          dropwndownValueName="name"
                        />
                      </div>
                      <div className='col-md-12'>
                        <FieldArray name="questions">
                          {({ push, remove }) => (
                            <>
                              {values.questions.map((data, i) => (
                                <>
                                  <div className='form_field'>
                                    <TextField
                                      id="outlined-multiline-static"
                                      label="Question"
                                      name={`questions[${i}].question`}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        handleChange(e);

                                      }}
                                    />
                                    {errors?.questions ?
                                      <FormHelperText id="component-error-text" style={{ left: '5px' }}>
                                        {errors?.questions[i]?.question}
                                      </FormHelperText> : null
                                    }
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.questions[i].answer === null
                                          || values.questions[i].answer === 1 ? false : true}
                                        onChange={
                                          (e) => {
                                            return (
                                              e.target.checked ?
                                                setFieldValue(`questions[${i}].answer`, 1) :
                                                setFieldValue(`questions[${i}].answer`, null)
                                            )

                                          }}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Option 1"
                                        name={`questions[${i}].option1`}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.questions ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.questions[i]?.option1}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.questions[i].answer === null
                                          || values.questions[i].answer === 2 ? false : true}
                                        onChange={
                                          (e) => (e.target.checked ? setFieldValue(`questions[${i}].answer`, 2) : setFieldValue(`questions[${i}].answer`, null))}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        rows={4}
                                        label="Option 2"
                                        name={`questions[${i}].option2`}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.questions ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.questions[i]?.option2}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.questions[i].answer === null
                                          || values.questions[i].answer === 3 ? false : true}
                                        onChange={
                                          (e) => (e.target.checked
                                            ? setFieldValue(`questions[${i}].answer`, 3)
                                            : setFieldValue(`questions[${i}].answer`, null)
                                          )}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Option 3"
                                        name={`questions[${i}].option3`}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.questions ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.questions[i]?.option3}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                    <div className='col-md-6 form_field d-flex'>
                                      <Checkbox
                                        disabled={values.questions[i].answer === null
                                          || values.questions[i].answer === 4 ? false : true}
                                        onChange={
                                          (e) => (e.target.checked ? setFieldValue(`questions[${i}].answer`, 4) : setFieldValue(`questions[${i}].answer`, null))}
                                      />
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Option 4"
                                        name={`questions[${i}].option4`}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          handleChange(e);
                                        }}
                                      />
                                      {errors?.questions ?
                                        <FormHelperText id="component-error-text">
                                          {errors?.questions[i]?.option4}
                                        </FormHelperText> : null
                                      }
                                    </div>
                                    {errors?.questions ?
                                      <FormHelperText id="answer_error">
                                        {errors?.questions[i]?.answer}
                                      </FormHelperText> : null
                                    }
                                    {values.questions[i].answer ? <span style={{ color: '#f88214' }}>Please unselect selected answer to change new one</span> : null}
                                  </div>
                                  <div className="submit_btn_container add_quiz_btn_cont" >
                                    {values.questions.length === 1 || values.questions.length === i + 1 ?
                                      <div className="gender_btn btn_active"
                                        style={{ marginLeft: 'auto', width: 'auto', padding: '10px 20px', maxWidth: '100%' }}
                                        onClick={() => { push(newQues) }}
                                      >
                                        <h6>Add Another Question</h6>
                                      </div>
                                      : null
                                    }
                                    {values.questions.length >= 2 && i != 0 ?
                                      <div className="gender_btn "
                                        style={{ marginLeft: 'auto', width: 'auto', padding: '10px 20px', maxWidth: '100%' }}
                                        onClick={() => { remove(i) }}
                                      >
                                        <h6>Remove Question</h6>
                                      </div> : null
                                    }
                                  </div>
                                </>
                              ))
                              }
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                    <div className="submit_btn_container">
                      <div
                        className="gender_btn__cc btn_active"
                        onClick={() => {
                          setFieldValue('publish_status', 1)
                          setTimeout(() => { handleSubmit(); }, 500);
                        }}
                      >
                        <h6> Publish</h6>
                      </div>
                      {isAdmin() == 2 ? <div className="gender_btn__cc"
                        onClick={() => {
                          setFieldValue('publish_status', 0)
                          setTimeout(() => { handleSubmit(); }, 500);
                        }}
                      >
                        <h6>Save as Draft</h6>
                      </div> : null}
                      <div className="gender_btn__cc" onClick={() => { navigation(-1) }}>
                        <h6>Cancel</h6>
                      </div>
                    </div>
                  </Form>
                )
                }
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-100"></div>
      {role == 2 ? <Footer /> : null } 
    </div>
  )
}
