import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register/Register";
/* import OTPScreen from "./pages/OTPScreen/OTPScreen"; */
import OTPVerify from "./pages/OTPVerify/OTPVerify";
import CreatePassword from "./pages/CreatePassword/CreatePassword";
import ProfileRegisteration from "./pages/Register/ProfileRegisteration/ProfileRegisteration";
import Createcourse from "./pages/Course/CreateCourse/Createcourse";
import Dashboard from "./pages/Dashboard/Dashboard";
import Auth from "./pages/Login/Auth/Auth";
import Course from "./pages/Course/Course";
import { ToastContainer } from "react-toastify";
import Terms from "./pages/StaticPages/Terms/Terms";
import "react-toastify/dist/ReactToastify.css";
import Support from "./pages/StaticPages/Support/Support";
import ProtectedRoute from "./services/ProtectRoute";
import CoursePreview from "./pages/CoursePreview/CoursePreview";
import OTPSuccess from "./pages/OTPSuccess/OTPSuccess";
import CreateAssignment from "./pages/Assignment/CreateAssignment/CreateAssignment";
import Assignment from "./pages/Assignment/Assignment";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ForgotOTP from "./pages/ForgotOTP/ForgotOTP";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Loader from "./components/loader/loader";
import CreateQuiz from "./pages/Quiz/CreateQuiz/CreateQuiz";
import ManageQuiz from "./pages/Quiz/ManageQuiz";
import ManageStudents from "./pages/ManageStudents/ManageStudents";
import { isAdmin } from "./helpers/utils";
import AdminDashboard from "./pages/AdminPages/Dashboard/AdminDashboard";
import ManageStudentsAdmin from "./pages/AdminPages/ManageStudents/ManageStudents";
import ManageParentsAdmin from "./pages/AdminPages/ManageParents/ManageParents";
import { useEffect, useState } from "react";
import ManageTeachersAdmin from "./pages/AdminPages/ManageTeachers/ManageTeachers";
import TutorProfile from "./pages/TutorProfile/TutorProfile";
import { useSelector } from "react-redux";
import ManageCourses from "./pages/AdminPages/ManageCourses/ManageCourses";
import ManageAssignment from "./pages/AdminPages/ManageAssignment/ManageAssignment";
import ManagePackageAdmin from "./pages/AdminPages/ManagePackage/ManagePackage";
import ManageBoard from "./pages/AdminPages/ManageBoard/ManageBoard";
import ManageLocationMaster from "./pages/AdminPages/ManageLocationMaster/ManageLocationMaster"
import ManageAcademyInfo from "./pages/AdminPages/ManageAcademyInfo/ManageAcademyInfo";
import AcademyImages from "./pages/AdminPages/AcademyImages/AcademyImages"
import {Helmet} from "react-helmet";
import ManageFaq from "./pages/AdminPages/ManageFAQ/ManageFaq";
import ManageGrade from "./pages/AdminPages/ManageGrade/ManageGrade";
import ManageCategory from "./pages/AdminPages/ManageCategory/ManageCategory";
import EditCourse from "./pages/Course/CreateCourse/EditCourse";
import EditAssignment from "./pages/Assignment/CreateAssignment/EditAssignment";
import EditQuiz from "./pages/Quiz/CreateQuiz/EditQuiz";
import ManageBanners from "./pages/AdminPages/ManageBanners/ManageBanners";
import CmscontentList from "./pages/AdminPages/ManageCms/CmscontentList";
import ManageQuizes from "./pages/AdminPages/ManageQuizes/ManageQuizes"
import AdminProfile from "./components/Header/AdminProfile";
import About from "./pages/StaticPages/About/About";
import VideoMeet from "./pages/VideoMeet/VideoMeet";
import {  useLocation } from "react-router-dom";
import TawkTo from 'tawkto-react'
import ManageNotification from "./pages/AdminPages/ManageNotification/manageNotification";
import ManagePayment from "./pages/ManagePayment";


function App() {
  const store = useSelector((state) => state.reducer.userRole);
  const location = useLocation();
  const checkLocation = location.pathname === '/admin' ? true : false;

  const [userRole, setUserRole] = useState(2);
  useEffect(() => {
    
    setUserRole(isAdmin());
    setInterval(() => { setUserRole(isAdmin()); }, 100);
  }, []);
  useEffect(()=>{
    var tawk = new TawkTo(' 6274ff43b0d10b6f3e70e7dd', '1g2cga8hm')
   

    tawk.onStatusChange((status) => 
    {
        console.log(status)
    })
  },[])
  return (
    <div>
       { userRole === 4 || checkLocation ?
     <Helmet>
     <meta charset="utf-8" />
    <title>I2 Global - Admin Panel</title>
    <meta name="description" content="Admin page" />
         <meta name="description" content="Admin " />
     </Helmet>
     :
        <Helmet>
        <meta charset="utf-8" />
       <title>I2 Global - Tutor Panel</title>
       <meta name="description" content=" Tutor page" />
            <meta name="description" content="Tutor " />
        </Helmet>     
     }

      <Loader />
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/admin" element={<Auth />} />
        <Route path="/support" element={<Support />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={userRole ? <Navigate to="/dashboard"/> : <Navigate to="/login"/>} />
        <Route path="/register" element={<Register />} />
       {/*  <Route path="/otpscreen" element={<OTPScreen />} /> */}
        <Route path="/otpverify" element={<OTPVerify />} />
        <Route path="/otpsuccess" element={<OTPSuccess />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-otp" element={<ForgotOTP />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        

        <Route element={<ProtectedRoute allowedRoles={[2, 4]} />}>
          <Route path="/dashboard" element={userRole === 2 ? <Dashboard /> : <AdminDashboard />}/>
          <Route path="admin/manage-students" element={userRole === 4 ? <ManageStudentsAdmin /> : null}/>
          <Route path="admin/payment-history" element={userRole === 4 ? <ManagePayment /> : null}/>
          <Route path="admin/manage-parents" element={userRole === 4 ? <ManageParentsAdmin /> : null}/>
          <Route path="admin/manage-teachers" element={userRole === 4 ? <ManageTeachersAdmin /> : null}/>
          <Route path="admin/manage-courses"element={userRole === 4 ? <ManageCourses /> :null}/>
          <Route path="admin/manage-courses/pending"element={userRole === 4 ? <ManageCourses /> :null}/>
          <Route path="admin/manage-courses/create-course" element={userRole === 4 ? <Createcourse /> : null} />
          <Route path="admin/manage-assignments" element={userRole === 4 ? <ManageAssignment /> : null} />
          <Route path="admin/manage-cms" element={userRole === 4 ? <CmscontentList /> : null} />
          <Route path="admin/manage-assignments/create-assignment" element={userRole === 4 ? <CreateAssignment /> : null} />
          <Route path="admin/manage-package" element={userRole === 4 ? <ManagePackageAdmin /> : null} />
          <Route path="admin/manage-board" element={userRole === 4 ? <ManageBoard /> : null} />
          <Route path="admin/manage-faq" element={userRole === 4 ? <ManageFaq /> : null} />
          <Route path="/admin/academy-master" element={userRole === 4 ? <ManageLocationMaster /> : null} />
          <Route path="/admin/academy-info" element={userRole === 4 ? <ManageAcademyInfo /> : null} />
           <Route path="/admin/academy-images" element={userRole === 4 ? <AcademyImages /> : null } /> 
          <Route path="/admin/manage-grade" element={userRole === 4 ? <ManageGrade /> : null } />
          <Route path="/admin/manage-category" element={userRole === 4 ? <ManageCategory /> : null } />
          <Route path="/admin/manage-banners" element={userRole === 4 ? <ManageBanners /> : null} />
          <Route path="admin/manage-quizes" element={userRole === 4 ? <ManageQuizes /> : null} />
          <Route path="/manage-quiz/create-quiz" element={userRole === 4 ? <CreateQuiz /> : <CreateQuiz /> } />
          <Route path="/manage-quiz/edit-quiz" element={userRole === 4 ? <EditQuiz /> : <EditQuiz /> } />
          <Route path="/create-course" element={userRole === 2 ?<Createcourse /> : <Navigate to="/dashboard"/>} />
          <Route path="/edit-course" element={userRole === 2 ?<EditCourse /> : <EditCourse />} />
          <Route path="/ProfileRegisteration"element={userRole === 2 ? <ProfileRegisteration /> : <Navigate to="/dashboard"/>}/>
          <Route path="view-course" element={userRole === 2 ? <Course /> : <Navigate to="/dashboard"/>} />
          <Route path="/coursepreview" element={userRole === 2 ?  <CoursePreview />: <Navigate to="/dashboard"/>} />
          <Route path="/assignment" element={userRole === 2 ? <Assignment /> : <Navigate to="/dashboard"/>} />
          <Route path="/edit-assignemnt" element={<EditAssignment />} />
          <Route path="/assignment/create-assignment" element={userRole === 2 ? <CreateAssignment /> : <Navigate to="/dashboard"/>}/>
          <Route path="/manage-quiz/create-quiz" element={userRole === 2 ? <CreateQuiz /> : <Navigate to="/dashboard"/>} />
          <Route path="/manage-quiz" element={userRole === 2 ?  <ManageQuiz /> : <Navigate to="/dashboard"/>} />
          <Route path="/manage-quiz/edit-quiz" element={<EditQuiz />}> </Route>
          <Route path="/manage-students/:id" element={userRole === 2 ? <ManageStudents /> : <Navigate to="/dashboard"/>} />
          <Route path="/profile" element={<TutorProfile />} />
          <Route path="/admin-profile" element={userRole === 4 ?<AdminProfile />:null} />
          <Route path="/admin/manage-notification" element={userRole === 4 ?<ManageNotification />:null} />
          <Route path="/video-meet" element={<VideoMeet />} />
        </Route>

        

        <Route path="/manage-students1" element={<ManageStudents />} />
      </Routes>
      <ToastContainer />
    
    </div>
  );
}

export default App;
