import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { axiosToken } from '../../../services/api';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import AddNewCms from './AddNewCms';
import EditCms from './EditCms';


export default function ContentList() {
    const [rows, setRows] = useState([]);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const [editData, setEditData] = useState([]);
    const [add_std, setAdd_std] = useState(false);
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        getCmsData(newPage, rowPerPage)
    };
    const getCmsData = (page, row) => {
        axiosToken.get(`/admin/cms_details_api?page=${page}&item=${row}`)
            .then((res) => {
                
                setTotal(res.data.total_count); 
               setRows(res.data.data)
            })
            .catch((err) => { console.log(err) })
    }
    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
        getCmsData(currentPage, event.target.value)
    };
    const label = { inputProps: { 'aria-label': 'Block/UnBlock User' } };
    const blockUnBlock = (id, value) => {
        axiosToken.delete('admin/cms_details_api?id=' + id + '&status=' + value)
            .then((res) => {
                if(res.data.status){
                toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT });
                getCmsData(currentPage, rowPerPage)
            }
            else{
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
            })
            .catch((err) => { console.log(err) })
    }
    useEffect(() => { getCmsData(currentPage, rowPerPage) }, [])

    return (
        <> 
        <AddNewCms openDialogProp={add_std}  onClose={()=>{setAdd_std(false);getCmsData(currentPage, rowPerPage)}} onCancel={()=>{setAdd_std(false)}} />
        <EditCms openDialogProp={showEdit} data={editData} onClose={()=>{setShowEdit(false);getCmsData(currentPage, rowPerPage)}} onCancel={()=>{setShowEdit(false)}} />
            <div className='student_table_container' id='manage_student_table'>
                <nav aria-label="breadcrumb ">
                    <ol className="breadcrumb empty">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard" activeClassName="activeRoute">Dashboard</NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page"> Manage CMS</li>
                    </ol>
                </nav>
                <div
                    className="gender_btn__cc btn_active add_new_std"
                    style={{ maxWidth: '200px' }}
                    onClick={() => { setAdd_std(true) }}
                >
                    <h6>Add New CMS</h6>
                </div>
                <div className='student_table_content'>
                    <div className='left__side'>
                        <h6>CMS ({total}) </h6>
                    </div>

                </div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.cms_key}
                                    </TableCell>
                                    <TableCell><p className='cms_description'>{row?.cms_value}</p></TableCell>
                                    <TableCell>
                                        <div className='student_action_container'>
                                            <span className='action__img feedback__given'>
                                                <Tooltip title="Active/Deactive" arrow>
                                                    <Switch
                                                        {...label}
                                                        defaultChecked={row.is_active}
                                                        onChange={(e) => (blockUnBlock(row.id, e.target.checked ? 1 : 0))}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Edit FAQ" arrow
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => { setEditData(row); setShowEdit(true) }}
                                                >
                                                    <EditIcon />
                                                </Tooltip>
                                            </span>
                                            <span className='action__img'>
                                                {/* <img src={require('../../../assets/icons/feedback_received.png')} /> */}
                                            </span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>

    )
}
