import { useEffect } from "react";

import Images from "../../constant/Images";
import "../CoursePreview/CoursePreview.css";

import { useDispatch } from "react-redux";
import { loading } from "../../store/actions";
var todayDate = new Date();
//const yyyy = todayDate.getFullYear();
let mm = todayDate.getMonth() + 1; // Months start at 0!
let dd = todayDate.getDate();
if (dd < 10) dd = "0" + dd;
if (mm < 10) mm = "0" + mm;
var todayDate = dd + "/" + mm;
const ReactDOMServer = require("react-dom/server");
const HtmlToReactParser = require("html-to-react").Parser;
export default function CoursePreview({ data }) {
  //const { course_name } = props;
  const htmlInput = "content";
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(data.content);
  const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

  //const [edit, setEdit] = React.useState(false);
 
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loading(false));
  }, []);
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="mobileview">
                  <div className="flexe">
                    <h4>Preview</h4>
                    {/* <Button
                      className="btnedit"
                      color="error"
                      variant="outlined"
                      startIcon={<ModeEditIcon />}
                      onClick={handleCancel}
                    >
                      Edit
                    </Button> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12 picpreview">
                <div className="mobileview"></div>
                <div className="videospacing">
                  <video
                    src={
                      "https://s3-ap-south-1.amazonaws.com/i2-global/" +
                      data?.course_intro
                    }
                    controls
                    className="videosize"
                  ></video>
                </div>
                <div className="class_box">
                  <p className="class__title">{data?.course_name}</p>
                  <h5 className="class__head description__data">{data?.description}</h5>
                  <p className="class__head">Travis Scott</p>
                  <div className="cardflex">
                    <p className="cardtext">1 of 3 . 40min</p>
                    <p className="cardtext">
                      {data.class_date[0]}.{data.class_start_on}
                    </p>
                  </div>
                </div>
                <div className="empty">
                  <button className="btnenroll">Enroll Now</button>
                </div>
                <div className="empty">
                  <p className="textcolor">About Tutor</p>
                  <div className="row">
                    <div className="col-md-2">
                      <img id="circle" alt="img" src={Images.RoundImg} />
                    </div>
                    <div className="col-md-10">
                      <p className="imgtext">Travis Scott</p>
                      <div className="startext"></div>
                    </div>
                  </div>
                </div>
                <div className="row title2 ">
                  <p className="textcolor">Topics covered</p>
                    <div className="html__content">
                    {reactElement}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
