import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";

export default function AddNewTeacher({ openDialogProp, onClose,onCancel }) {
    const TeacherSchema  = Yup.object({
        name:Yup.string().required(constant.addTutor.name).trim(),
       /*  .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), */
        email:Yup.string().required(constant.addTutor.email).email(constant.addTutor.valid_email).trim(),
        password:Yup.string().required(constant.addTutor.password).trim(),
    })
    const initialValues = {
        role: 'tutor',
        email: '',
        password: '',
        name: ''
    }

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
   
    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New Tutor</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={TeacherSchema}
                                onSubmit={(values) => {
                                   axiosToken.post('/admin/managetutors',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched,handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="name"
                                                                label="Tutor Name"
                                                                error={Boolean(touched.name && errors.name)}
                                                                helperText={touched.name && errors.name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="email"
                                                                label="Tutor Email"
                                                                error={Boolean(touched.email && errors.email)}
                                                                helperText={touched.email && errors.email}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.email}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-4'>
                                                        
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="password"
                                                                label="Password"
                                                                error={Boolean(touched.password && errors.password)}
                                                                helperText={touched.password && errors.password}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.password}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit() }}
                                                    >
                                                        <h6 >Add Tutor</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" onClick={() => { onCancel(false) }}>
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}
