import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../components/Header/Header";
import { loading } from "../../store/actions";
import "../ManageStudents/ManageStudents.css";
import CountBox from "./components/CountBox";
import StudentTable from "./components/StudentTable";
import Footer from "../../components/Footer/Footer";

export default function ManageStudents() {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {dispatch(loading(false));}, 1000);
  }, []);
  return (
    <div>
      <Header />
      <div className="container-fluid px-5 pt-5">
        <h4 className="page__title">Students</h4>
        <CountBox />
        <StudentTable />
      </div>
      <div className="mb-100"></div>
       <Footer /> 
    </div>
  );
}
