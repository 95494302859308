import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { loading } from '../../store/actions';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import ManagePaymentListTable from './components/ManagePaymentTable';




export default function ManagePayment() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loading(false))
  }, [])
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='container-fluid px-5'>
        <div className='row'>
          <div className='col-md-12'>
          <ManagePaymentListTable/>
          </div>
        </div>
      </div>
    </div>
  )
}
