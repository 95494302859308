import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { axiosToken } from "../../../services/api";
import CloseIcon from "@mui/icons-material/Close";
import constant from "../../../constant/Validation";

export default function ViewStudentProfile({ openDialogProp, onClose, id }) {
  const [open, setOpen] = useState(false);

  const [tutorProf, setTutorProf] = useState([]);
  const [cert_pop, setcert_pop] = useState(false);
  const handleClose = () => {
    onClose(false);
    setOpen(false);
  };
  useEffect(() => {
    setOpen(openDialogProp);
    if(id){
      getTutorViewProfile();
    }
  }, [openDialogProp]);

  const getTutorViewProfile = () => {
    axiosToken
      .get("admin/managestudents?id=" + id)
      .then((res) => {
        setTutorProf(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };



  return (
    <>
      <Dialog
        open={open}
        fullWidth={"md"}
        maxWidth={"md"}
        keepMounted={false}
        aria-describedby="alert-dialog-slide-description"
        className="assignment__dialog add_new_std"
      >
        <span
          className="close__btn"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </span>
        <DialogTitle>Student Profile</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h6 className="child_text child_name">
              {tutorProf?.profile_pic ? (
                <img
                  src={constant.common.bidBucket + tutorProf?.profile_pic}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <img
                  src={require("../../../assets/img/no_img.jpg")}
                  id="profile"
                  className="user_img_tutor__profiles"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              )}
            </h6>
            <div className="justify-content-sm-between tutor__info mt-3">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h6
                      className="child_text child_name"
                      style={{ textTransform: "capitalize" }}
                    >
                      Name :{" "}
                      <span>{tutorProf.name ? tutorProf.name : "N/A"}</span>
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <h6 className="child_text child_name">
                      Email :{" "}
                      <span>{tutorProf.email ? tutorProf.email : "N/A"}</span>
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="child_text child_name">
                      DOB : <span>{tutorProf.dob ? tutorProf.dob : "N/A"}</span>
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <h6 className="child_text child_name">
                      Gender :
                      <span style={{ textAlign: "initial" }}>
                        {" "}
                        {tutorProf.gender ? tutorProf?.gender : "N/A"}
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h6 className="child_text child_name">
                      Mobile :{" "}
                      <span>{tutorProf.mobile ? tutorProf.mobile : "N/A"}</span>
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <h6 className="child_text child_name">
                      Academy Branch :{" "}
                      <span>
                        {tutorProf.academy_branch__branch_location_name
                          ? tutorProf.academy_branch__branch_location_name
                          : "N/A"}{" "}
                        {tutorProf.address_2 ? tutorProf.address_2 : null}
                      </span>
                    </h6>
                  </div>
                </div>
                {/* <div className='row'>
                  <div className='col-md-6'>
                    <h6 className='child_text child_name' style={{ textTransform: 'capitalize' }}>Area : <span>{tutorProf.area ? tutorProf.area : 'N/A'}</span></h6>
                  </div>
                  <div className='col-md-6'>
                    <h6 className='child_text child_name' style={{ textTransform: 'capitalize' }}>City : <span>{tutorProf.city ? tutorProf.city : 'N/A'}</span></h6>
                  </div>

                </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <h6
                      className="child_text child_name"
                      style={{ textTransform: "capitalize" }}
                    >
                      Grade :{" "}
                      <span>
                        {tutorProf.student_grade__grade
                          ? tutorProf.student_grade__grade
                          : "N/A"}
                      </span>
                    </h6>
                  </div>

                  <div className="col-md-6">
                    <h6 className="child_text child_name">
                      Academy Master Name :{" "}
                      <span>
                        {tutorProf.academy_master__academy_name
                          ? tutorProf.academy_master__academy_name
                          : "N/A"}
                      </span>
                    </h6>
                  </div>
                </div>
                {/*  <div className='row'>
                  <div className='col-md-6'>
                    <h6 className='child_text child_name'>Mobile : <span>{tutorProf.mobile_no ? tutorProf.mobile_no : 'N/A'}</span></h6>
                  </div>
                    <div className='col-md-6'>
                    <h6 className='child_text child_name'>Pincode : <span>{tutorProf.pincode ? tutorProf.pincode : 'N/A'}</span></h6>
                  </div>  
                </div> */}
                {/* <h6 className='prof___details_font mt-2'>Work Experience</h6>

                <div className='child_list child_list_header '>
                  <h4 className='child_text pack_head'>Tutor Role</h4>
                  <h4 className='child_text pack_head child_name'>Organization</h4>
                  <h4 className='child_text pack_head child_name'>From Date</h4>
                  <h4 className='child_text pack_head'>To Date</h4>
                </div> */}

                {/* <div className='child_list_body package__list'>
                  {tutorProf.workexperience?.map((data, i) => (
                    <div className='child_list'>
                      <h4 className='child_text pack_text class_name_pack'>{data.role}</h4>
                      <h4 className='child_text pack_text class_name_pack' style={{ textTransform: 'capitalize' }}>

                        {data.organization}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{data.from_date}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{data.to_date}</h4>
                    </div>
                  ))}
                </div> */}

                {/* <h6 className='prof___details_font mt-2'>Educational Details</h6> */}

                {/* <div className='child_list child_list_header '>

                  <h4 className='child_text pack_head child_name' >College Name</h4>
                  <h4 className='child_text pack_head child_name'>Degree</h4>
                  <h4 className='child_text pack_head child_name'>Major</h4>
                  <h4 className='child_text pack_head'>Grade</h4>
                  <h4 className='child_text pack_head child_name'>From Date</h4>
                  <h4 className='child_text pack_head'>To Date</h4>
                </div> */}
                {/* <div className='child_list_body package__list'>
                  {tutorProf.educationdetails?.map((data, i) => (
                    <div className='child_list'>
                      <h4 className='child_text pack_text class_name_pack' style={{ textTransform: 'capitalize' }}>{data.college_name}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{data.degree}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{data.major}</h4>
                      <h4 className='child_text pack_text class_name_pack'>
                        {data.grade}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{data.from_date}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{data.to_date}</h4>
                    </div>
                  ))}
                </div> */}

                {/*   <h6 className=' mt-2'>Certification</h6>

                <div className='child_list child_list_header '>

                  <h4 className='child_text pack_head child_name'>Certificate</h4>
                  <h4 className='child_text pack_head'>From Date</h4>
                  <h4 className='child_text pack_head'>To Date</h4>
                </div>
                <div className='child_list_body package__list'>
                  {tutorProf.certification?.map((data, i) => (
                    <div className='child_list'>
                      <h4 className='child_text pack_text class_name_pack'>
                        {data.certificate ? data.certificate : 'N/A'}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{tutorProf.from_date ? tutorProf.from_date : 'N/A'}</h4>
                      <h4 className='child_text pack_text class_name_pack'>{tutorProf.from_date ? tutorProf.from_date : 'N/A'}</h4>
                    </div>
                  ))}
                </div> */}

                {/* <h6 className='prof___details_font mt-2'>Accomplishments</h6> */}
                {/* <div className='child_list child_list_header '>
                  <h4 className='child_text pack_head'>Name</h4>
                  <h4 className='child_text pack_head child_name'>Certificate</h4>
                </div> */}
                {/* <div className='child_list_body package__list'>
                  {tutorProf.accomplishment?.map((data, i) => (
                    <div className='child_list'>
                      <h4 className='child_text pack_text class_name_pack' style={{textTransform:'capitalize'}}>{tutorProf.accomplishment[i]?.name ? tutorProf.accomplishment[i]?.name : 'N/A'}</h4>
                      <h4 className='child_text child_name' style={{cursor:'pointer'}}
                      onClick={() => {window.open(constant.common.bidBucket + tutorProf?.accomplishment[i]?.certificate, '_blank');  }}> 
                           {tutorProf?.accomplishment[0]?.certificate ?
                           <img className="course_img" src={constant.common.bidBucket + tutorProf?.accomplishment[i]?.certificate} /> : 'N/A'}                
                      </h4>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
