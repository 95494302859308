import { useEffect, useState } from 'react';
import '../AssignmentDialog/AssignmentDialog.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { axiosToken } from '../../services/api';
import { toast } from "react-toastify";
import { reloadAssignmentData } from '../../store/actions';
import { useDispatch } from 'react-redux';




export default function AssignmentDialog({ openDialogProp, dialogData, type, id }) {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const assignmentAction = (id, status) => {
        const payload = { id: id, status: status }
        axiosToken.post('tutor/updateassignmentstatus', payload)
            .then((res) => {
                if (res.data.status) {
                    dispatch(reloadAssignmentData(true))
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((err) => { console.log(err) })
    }
    const publishAssignment = (id) =>{
        const payload = { assignment_id: id, publish_status:1 }
        axiosToken.post('tutor/tutor_addassignment',payload)
        .then((res)=>{
            if (res.data.status) {
                dispatch(reloadAssignmentData(true))
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        })
        .catch((err)=>{console.log(err)})
    }
    useEffect(() => { setOpenDialog(openDialogProp) }, [openDialogProp])
    return (
        <div >
            <Dialog
                open={openDialog}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog'
            >
                <DialogTitle>{dialogData?.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {dialogData?.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div
                        className="gender_btn btn_active"
                        onClick={() => {
                            if (type == 'approve') {
                                assignmentAction(id, 4)
                            } else if (type == 'redo') {
                                assignmentAction(id, 3)
                            } else if (type == "publish_assignment") {
                                publishAssignment(id)
                            }
                            setOpenDialog(false)

                        }}
                    >
                        <h6>Yes</h6>
                    </div>
                    <div className="gender_btn" >
                        <h6 onClick={() => { setOpenDialog(false) }}>No</h6>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
