import { TextField, Tooltip } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import FeedbacktoStudent from "./FeedbacktoStudent";
import { axiosToken } from "../../../services/api";
import { useParams } from "react-router-dom";
import FeedbackReview from "./FeedbackReview";
import FeedbackEdit from "./FeedbackEdit";
import '../../ManageStudents/components/StudentTable.css'
import UploadCertificate from "./UploadCertificate";
import Images from "../../../constant/Images";


export default function StudentTable({ data }) {
  /* const [rows, setRows] = React.useState([]); */
  const [rows, setRows] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [add_std, setAdd_std] = useState(false);
  const [studentid, setStudentid] = useState();
  const [courseid, setCourseid] = useState();
  const [show_map, setShowMap] = useState(false);
  const [editData, setEditData] = useState([]);
  const [edit_feed, setEdit_feed] = useState(false);
  const [par_id, setParId] = useState(null);
  const [cert_pop,setcert_pop] = useState(false)
  const [count, setCount] = useState('');

  /*  const [feedBackGiven, setfeedBackGiven] = useState(false); */
  useEffect(() => { }, [add_std])
  const { id } = useParams();
  const searchRef = useRef();
  const [search, setSearch] = useState([]);
  useEffect(() => {
   getStudentReview();
  }, []);
  useEffect(() => {
    getStudentFullDetails();
  }, []);
  const searchCourse =(name) =>{
    axiosToken.get(`tutor/liststudentsubcribedinfo?search=${name}&course_id=${id}&page=${currentPage}&item=${rowPerPage}`)
    .then((res) => {  setTotal(res.data.name); setRows(res.data.data) })
    .catch((err) => { console.log(err) })
}
  useEffect(() => {
    axiosToken.get('tutor/tutordetailcount?course_id=' + id)
      .then((res) => {
        setCount(res.data)
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
    axiosToken.get(`/tutor/liststudentsubcribedinfo?course_id=${id}&page=${newPage}&item=${rowPerPage}`)
      .then((res) => {
        setTotal(res.data.total_count)
        setRows(res.data.data)
      })
      .catch((err) => { console.log(err) })
  };

  const getStudentFullDetails = () => {
    axiosToken.get(`/tutor/liststudentsubcribedinfo?course_id=${id}&page=${currentPage}&item=${rowPerPage}`)
      .then((res) => { setTotal(res.data.total_count)
        setRows(res.data.data)
      })
      .catch((err) => { console.log(err) })
  }
  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
    axiosToken.get(`/tutor/liststudentsubcribedinfo?course_id=${id}&page=${currentPage}&item=${event.target.value}`)
      .then((res) => {
        setTotal(res.data.total_count)
        setRows(res.data.data)
      })
      .catch((err) => { console.log(err) })
  };

  const getStudentReview = (stid) => {
    const params = {course_id: id}

    if (stid) params.set('student_id', stid)
    axiosToken.get("tutor/gettutorfeedback", {params})
      .then((res) => {setEditData(res.data[0]);})
  }
  useEffect(() => { }, [editData])

  return (
    <>
      <FeedbacktoStudent openDialogProp={add_std} onClose={() => { setAdd_std(false);  getStudentFullDetails(); getStudentReview(); }} studentid={studentid} courseid={id} />
      <FeedbackEdit openDialogProp={edit_feed} editData={editData} onClose={() => { setEdit_feed(false); getStudentFullDetails(); getStudentReview(); }} studentid={studentid} courseid={id} />
      <FeedbackReview openDialogProp={show_map} onClose={() => { setShowMap(false) }} studentid={studentid} courseid={id} />
      <UploadCertificate  openDialogProp={cert_pop} onClose={() => {setcert_pop(false); getStudentFullDetails(); getStudentReview()}} studentid={studentid} />
      <div className="student_table_container">
        <div className="student_table_content">
          <div className="left__side">
            <h6>Students({count.mystudents}) </h6>
          </div>
          <div className='right__side student_search course_cont' style={{display:'contents'}} >
                         <form  ref={searchRef}>
                         <TextField  
                            className="fieldtext"
                            type="text"
                            name="search"
                            label="Search"
                            variant="outlined"  
                            onChange={(e)=>{searchCourse(e.target.value)}}
                        />
                         </form>
                    </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Subscribed Date</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Subscription Till</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
               {row.profile_pic ? <img src={row.profile_pic} className='studentss_pics' /> : <img src={Images.NoImg} className='tutor_img' />}    {row.name} 
                  </TableCell>
                  <TableCell>{row.registered_date}</TableCell>
                  <TableCell>{row.class}</TableCell>
                  <TableCell>{row.valid_to}</TableCell>

                 
                  <TableCell>

                    <div className="student_action_container" >
                      <span className="action__img feedback__given">
                        <Tooltip title="Feedback ">
                          {row.feedback_given ? <img
                            src={require("../../../assets/icons/feedback_given.png")}
                            onClick={() => {
                              setEdit_feed(true);
                              setStudentid(row.id);
                              setCourseid(row.class);
                              getStudentReview(row.id);
                            }}
                            className="feedback_given_icon"
                          /> : <img 
                            src={require("../../../assets/icons/edit.png")}
                            onClick={() => { setAdd_std(true); setStudentid(row.id); setCourseid(row.class) }}
                            className="feedback_given_icon"
                          /> }
                          </Tooltip>
                      </span>

                      <Tooltip title="Feedback Received">
                        <span className="action__img ">
                          {row.feedback_received ?  <img
                            src={require("../../../assets/icons/feedback_received.png")}
                            className="feedback_received_icon"
                            onClick={() => { setShowMap(true); setStudentid(row.id); setCourseid(row.class) }}
                          /> :null}
                        </span></Tooltip>
                      
                         {!row.certificate_url ?   <Tooltip title={'Upload Certificate'} 
                         onClick={()=>{setcert_pop(true);setStudentid(row.id)}}>
                              <img src={require('../../../assets/img/certificate.png')} style={{width:'25px',height:'25px',marginLeft:'10px',cursor:'pointer'}}  />
                        </Tooltip> :  
                        <Tooltip title={'View Certificate'} 
                         onClick={()=>{window.open(row.certificate_url, '_blank');}}>
                              <img src={require('../../../assets/img/certificate.png')} style={{width:'25px',height:'25px',marginLeft:'10px',cursor:'pointer'}}  />
                        </Tooltip> } 
                        
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[1, 5, 10]}
          component="div"
          count={total}
          rowsPerPage={rowPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
     
    </>
  );
}
