import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { axiosToken } from '../../../../services/api';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

export default function MapStudentWithParent({ openDialogProp, onClose, par_id, onCancel }) {
    const [open, setOpen] = useState(openDialogProp);
    const [showAutoTag, setShowAutoTag] = useState(false);
    const [autoTags, setAutoTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [tempTag, setTempTag] = useState("");
    const [stdList, setStdList] = useState([]);
    const [dltCnf, setDltCnf] = useState(false);
    const [dltId, setDltId] = useState(null);

    const handleClose = () => { onClose(false); setOpen(false) };
    // useEffect(() => { getMapedStdDetails(); }, [par_id]);
    useEffect(() => { }, [dltCnf])

    const SearchStudent = (val) => {
        axiosToken.get('/admin/adminstudnetlist?email=' + val)
            .then((res) => { setAutoTags(res.data) })
    }
    const mapStdWithParent = () => {
        if (tags.length >= 1) {
            let stdId = []
            tags.map((d, i) => { stdId.push(d.id) });
            let payload = { parent: par_id, student: stdId }
            axiosToken.post('/admin/mapparentstudent', payload)
                .then((res) => {
                    toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT, });
                    getMapedStdDetails();
                    setTags([]);
                    onCancel(false)
                })
                .catch((err) => { 
                    toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT,})
                   })
        } else {
            toast.error("Please Add Student", { position: toast.POSITION.TOP_RIGHT, });
        }
    }
    const getMapedStdDetails = () => {
        axiosToken.get('student/liststudentviaparent?parent_id=' + par_id)
            .then((res) => {
                setStdList(res.data)
            })
    }
    const removeStudentMap = (id) => {
        axiosToken.delete(`admin/mapstudentparent?parent=${par_id}&student=${id}`)
            .then((res) => {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getMapedStdDetails()
            })
            .catch((err) => { console.log(err) })
    }
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])


    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'lg'}
                maxWidth={'lg'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Map Student With Parent</DialogTitle>
                <DialogContent>
                    <span className='close__btn_par' onClick={() => { onCancel(false) }}>
                        <Tooltip title="Close"><IconButton><CloseIcon /></IconButton></Tooltip>
                    </span>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <div className="row no-gutters">
                                <div className='container-fluid'>
                                    <div className='row mt-5'>
                                        <div className='col-md-6' style={{ position: 'relative' }}>
                                            <TextField
                                                className="fieldtext"
                                                type="text"
                                                name="name"
                                                autoComplete="off"
                                                label="Student Name"
                                                onBlur={() => { setTimeout(() => { setShowAutoTag(false) }, 300); }}
                                                onFocus={() => { setShowAutoTag(true); }}
                                                onChange={(e) => { SearchStudent(e.target.value) }}
                                                variant="outlined"
                                            />
                                            {showAutoTag ? (
                                                <div className="autoTags" style={{ width: '100%' }}>
                                                    {autoTags.map((data, i) => (
                                                        <h6
                                                            key={i}
                                                            onClick={() => {
                                                                setTags((pre) => [...pre, data,]);
                                                                setTempTag("");
                                                            }}
                                                        >
                                                            {data.email}
                                                        </h6>

                                                    ))}
                                                </div>
                                            ) : null}
                                            <div className="tags__list d-flex mt-2">
                                                {tags?.map((res, i) => (
                                                    <div className="pills" key={i}>
                                                        <h5>{res.name}</h5>
                                                        <p
                                                            onClick={() => {
                                                                setTags((currentTags) => currentTags.filter(tag => tag.email !== res.email))
                                                                // tags.pop(i); setTags((pre) => [...pre]);
                                                            }}
                                                        >
                                                            <CloseIcon sx={{ fontSize: 15 }} />
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DialogActions>
                                    <div
                                        className="gender_btn btn_active mt-5"
                                        style={{ maxWidth: '150px' }}
                                        onClick={() => { mapStdWithParent() }}
                                    >
                                        <h6>Map with Parent</h6>
                                    </div>
                                    <div className="gender_btn  mt-5" onClick={() => { onCancel(false) }} >
                                        <h6 >Cancel</h6>
                                    </div>
                                </DialogActions>

                            </div>
                        </div>
                        <div className='my_childs_list'>
                            <h6 className='my_child_title'>My Childs</h6>
                            <div className='child_list child_list_header'>
                                <h4 className='child_text child_name'>Name</h4>
                                <h4 className='child_text'>Grade</h4>
                                <h4 className='child_text child_email'>Email</h4>
                                <h4 className='child_text action_sec'>Action</h4>
                            </div>
                            <div className='child_list_body'>
                                {stdList.map((std, i) => (
                                    <div className='child_list'>
                                        <h4 className='child_text child_name'>{std.name}</h4>
                                        <h4 className='child_text'>{std.student_grade}</h4>
                                        <h4 className='child_text child_email'>{std.email}</h4>
                                        <h4 className='child_text action_sec'>
                                            {dltId == std.id? <div className='add_new_pack_cont'>
                                                <div className='col-md-12' style={{ position: 'relative' }}>
                                                    <p>Are You sure want to delete this child?</p>
                                                    <div className='add_btn__cont d-flex justify-content-center'>
                                                        <h6 className='gender_btn btn_active text-white' onClick={() => {removeStudentMap(std.id) }}>Yes</h6>
                                                        <h6 className='gender_btn' onClick={() => {
                                                            setTimeout(() => {
                                                                
                                                                setDltId(null)
                                                            }, 100);
                                                        }}>No</h6>
                                                    </div>
                                                </div>
                                            </div>:null}
                                            {dltId != std?.id ?
                                                <Tooltip title="UnMap Student" >
                                                    <IconButton>
                                                        <CloseIcon sx={{ fontSize: 18 }}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {setDltId(std.id) }} />
                                                    </IconButton>
                                                </Tooltip>
                                                : null}
                                        </h4>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div >
    )
}
