import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DatePicker from "react-multi-date-picker";
import Images from "../../../../constant/Images";
import TextField from "@mui/material/TextField";
import "../ScheduleDialog/ScheduleDialog.css";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { axiosToken } from "../../../../services/api";
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';
import UploadRecordVideo from './UploadRecordVideo';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useNavigate } from "react-router-dom";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { useSelector } from "react-redux";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ScheduleDialog({ data,isUpdate }) {
  const navigate = useNavigate();
  useEffect(() => { }, []);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [viewVideo, setViewVideo] = React.useState(false);
  const [uploadVideo, setuploadVideo] = React.useState(false);
  const [uploadData, setUploadData] = React.useState([]);
  const datePickerRef = useRef();
  const startTimeRef = useRef();
  const EndTimeRef = useRef()
  const store = useSelector((state) => state.reducer.userRole);

  /* const login_type =[
    {id:'admin',values:'Admin'},
   { id:'tutor',values:'Tutor'},
] */

const [dropdown,setDropdown] = React.useState();
  const [editData, setEditDate] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
    axiosToken
      .get("tutor/listtutorcoursecalender?course_id=" + data.id)
      .then((res) => {
        let data = res.data;
        data.map((d, i) => { });
        setTimeout(() => { setRows(data); }, 0);
      })
      .catch((err) => { console.log(err.response); });
  };
  const handleClose = () => {
    setOpen(false); setEdit(false);
    setuploadVideo(false); setViewVideo(false)
  }
  useEffect(()=>{
    //alert(store)
  },[])
  const editValue = (editData) => { return setEdit(editData); };
  return (
    <div className="class_schedule_popup_cont">
      <Tooltip title="View Class">
        <img
          src={Images.schedule}
          className="image_schedule"
          onClick={handleClickOpen}
        />
      </Tooltip>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="class_schedule_popup"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <h4 className="class_name">{data.course_name}</h4>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {!edit && !uploadVideo && !viewVideo ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="table_header">Class Name</TableCell>
                    <TableCell className="table_header">Date</TableCell>
                    <TableCell className="table_header">Start Time</TableCell>
                    <TableCell className="table_header">End Time</TableCell>
                    <TableCell className="table_header">Duration</TableCell>
                    {!edit ? (
                      <TableCell className="table_header">Tutor Name</TableCell>
                    ) : null}
                    {!edit ? (
                      <TableCell className="table_header">Status</TableCell>
                    ) : null}
                    <TableCell className="table_header">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!edit && rows != []
                    ? rows.map((row, i) => (
                      // console.log(new Date(row.class_start_on).toLocaleTimeString())
                      <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 }, }}>
                        <TableCell className="table_cell" component="th" scope="row"> {row.class_name}</TableCell>
                        <TableCell className="table_cell"> {row.date}</TableCell>
                        <TableCell className="table_cell">{row.class_start_on}</TableCell>
                        <TableCell className="table_cell">{row.class_end_on}</TableCell>
                        <TableCell className="table_cell">{row.duration}</TableCell>
                        <TableCell className="table_cell">{row.tutor_name}</TableCell>
                        <TableCell className="table_cell">
                          <span
                            className={
                              (row.status == 2 && "upcoming status") ||
                              (row.status == 0 && "inprogress status") ||
                              (row.status == 1 && "completed status")
                            }
                          >
                            {(row.status == 2 && "upcoming") ||
                              (row.status == 0 && "inprogress") ||
                              (row.status == 1 && "completed")}
                          </span>
                        </TableCell>
                        <TableCell className="table_cell action">
                          {row.class_video_url_status == 0 ? <Tooltip title="Upload Recorded Video">
                            <img src={require('../../../../assets/img/upload_icon.png')} onClick={() => { setuploadVideo(true); setUploadData(row) }} />
                          </Tooltip> :
                            <Tooltip title="View Recorded Video" style={{ cursor: 'pointer', marginRight: '5px' }} onClick={() => { setViewVideo(row.class_video_url) }}>
                              <OndemandVideoIcon />
                            </Tooltip>}
                          {row.status == 0 ? (
                            <>
                              {/* <img src={Images.edit_icon} className="action_icon" onClick={() => editValue(row)} /> */}
                              <Tooltip title="Join Class" style={{cursor:'pointer'}} onClick={() => navigate('/video-meet', { state: { data: row.meeting_link, subject: row.class_name, class_info: row } })}>
                                <CastForEducationIcon />
                              </Tooltip>
                            </>
                          ) : null}
                          {row.status == 2 ? (
                            <>
                              <Tooltip title="Edit Class">

                                <img src={Images.edit_icon} className="action_icon" onClick={() => editValue(row)} />
                              </Tooltip>
                              {/* <img src={Images.webinar_icon}
                                className="action_icon webinar_icon"
                                onClick={() => window.open(constant.common.jisti + row.meeting_link, "_blank")}
                              /> */}
                            </>
                          ) : null}
                          {/* {row.status == 1 ? (
                            <>
                              <img src={Images.view_icon} className="action_icon" onClick={()=>{console.log('view')}} />
                            </>
                          ) : null} */}
                        </TableCell>
                      </TableRow>
                    ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          {edit ? (
            <>
              <div className="edit__box">
                <div className="table_cell" component="th" scope="row">
                  <div className="course_title form_field position-relative mb-0">
                    <TextField
                      id="outlined-basic"
                      label="Class Title"
                      variant="outlined"
                      defaultValue={edit.class_name}
                      onBlur={(e) => {
                        edit.class_name = e.target.value;
                      }}
                      name="class_name"
                    />
                  </div>
                </div>
                <div className="table_cell">
                  <div className="date_picker_multiple">
                    <DatePicker
                      value={edit.date}
                      onChange={(e) => {
                        edit.date = e.format();
                      }}
                      format="YYYY-MM-DD"
                      minDate={new Date()}
                      ref={datePickerRef}
                    />
                    <img src={Images.calendar} className="picker__icon" onClick={() => datePickerRef.current.openCalendar()} />
                    <span className="lable__flot_custom">Select Date</span>
                  </div>
                </div>
                <div className="table_cell">
                  <div className="date_picker_multiple">
                    <DatePicker
                      value={new Date(`'01/01/1997 ${edit.class_start_on}'`)}
                      disableDayPicker
                      format="hh:mm A"
                      plugins={[<TimePicker />]}
                      ref={startTimeRef}
                      onChange={(e) => {
                        edit.class_start_on = e.format();
                      }}
                    />
                    <img src={Images.clock} className="picker__icon" onClick={() => startTimeRef.current.openCalendar()} />
                    <span className="lable__flot_custom">Start Time</span>
                  </div>
                </div>
                <div className="table_cell">
                  <div className="date_picker_multiple">
                    <DatePicker
                      value={new Date(`'01/01/1997  ${edit.class_end_on}'`)}
                      disableDayPicker
                      format="hh:mm A"
                      plugins={[<TimePicker />]}
                      ref={EndTimeRef}
                      onChange={(e) => {
                        edit.class_end_on = e.format();
                      }}
                    />
                    <img src={Images.clock} className="picker__icon" onClick={() => EndTimeRef.current.openCalendar()} />
                    <span className="lable__flot_custom">End Time</span>
                  </div>
                </div>
               
              </div>
              <div className="submit_btn_container flex__end">
                <div
                  class="gender_btn btn_active"
                  onClick={() => {
                    if(JSON.parse(localStorage.getItem('user')).role[0] ==2){
                      var payload={};
                      const dropdowns={login_type:'tutor'};
                      const edits={...edit,...dropdowns};
                      payload=edits;
                      axiosToken
                      .put("tutor/listtutorcoursecalender", payload)
                      .then((res) => {
                        isUpdate(true);
                        if (res.status == 200) {
                          toast.success("Class Data Updated Successfully.", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                         
                          setEdit(false);
                          handleClickOpen();
                        }
                      })
                      .catch((err) => {
                        toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT });
                      });
                    }
                    if(JSON.parse(localStorage.getItem('user')).role[0]==4){
                      var payload={};
                      const dropdowns={login_type:'admin'};
                      const edits={...edit,...dropdowns};
                      payload=edits;
                      axiosToken
                      .put("tutor/listtutorcoursecalender", payload)
                      .then((res) => {
                        
                        if (res.status == 200) {
                          toast.success("Class Data Updated Successfully.", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                          setEdit(false);
                         
                          handleClickOpen();
                        }
                      })
                      .catch((err) => {
                        toast.error(err.response.data.detail, { position: toast.POSITION.TOP_RIGHT });
                      });
                    }
                  
                  }}
                >
                  <h6>Save</h6>
                </div>
                <div class="gender_btn" onClick={() => setEdit(false)}>
                  <h6>Cancel</h6>
                </div>
              </div>
            </>
          ) : null}

          {uploadVideo ? <UploadRecordVideo data={uploadData} onClose={(e) => { setuploadVideo(false); handleClose(); }} onCancel={(e) => { setuploadVideo(false); }} refresh={() => { handleClickOpen() }} /> : null}
          {viewVideo ?
            <div className="video_preview">
              <video src={viewVideo} controls style={{ width: 800, height: 400, margin: '0 auto' }} />
              <div className="submit_btn_container flex__end">
                <div class="gender_btn" onClick={() => setViewVideo(false)}>
                  <h6>Close</h6>
                </div>
              </div>
            </div>
            : null}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
