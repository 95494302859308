import { useEffect, useRef, useState } from 'react';
import './VideoMeet.css'
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import StopIcon from '@mui/icons-material/Stop';
import { axiosToken, s3config } from '../../services/api';
import WaitingScreen from './WaitingScreen';
import AWS from 'aws-sdk';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export default function RecordScreen({ RecordStart, classEnd,data }) {
    const navigate = useNavigate();
    const [showWait, setShowWait] = useState(false);
    const [progress,setProgress] = useState(0);
    const stBtn = useRef(null);
    const stopBtn = useRef(null);
    let stream = null
    let audio = null
    let mixedStream = null
    let chunks = []
    let recorder = null
    let startButton = null
    let stopButton = null
    let downloadButton = null
    let recordedVideo = null
    let blob = null
    let config = { ...s3config, dirName: "recorded_video", };
    var bucket = new AWS.S3({
        ...s3config,
    })
    const uploadVideofn =(payload) =>{
        axiosToken.post('student/recorded_classes',payload)
        .then((res)=>{
            toast.success(res.data.message, { position: toast.POSITION.TOP_RIGHT,});
            setTimeout(() => { setShowWait(false);
                navigate('/dashboard')
            }, 1000)
        })
        .catch((err)=>{
            toast.error(err.response.data.detail, {position: toast.POSITION.TOP_RIGHT,});
        })
    }
    const  uploadfileNew = function(fileName, file) {
          const params = {Bucket: process.env.REACT_APP_S3_BUCKETNAME, Key: "recorded_videos/" + fileName, Body: file,ContentType: file.type, ACL:'public-read'};
        return bucket.upload(params, function(err, d) {
            if (err) {
              return false;
            }
            let payload ={
                "course_master":data.course,
                "class_master":data.id,
                "tutor":data.tutor,
                "blob":d.key,
                "thumb_nail":"thumbLink"                                
            }
            uploadVideofn(payload)
           
            return true;
          });
       
    }
    const upProg = function(fileName,file){
        setShowWait(true)
        uploadfileNew(fileName,file).on('httpUploadProgress',function(progress){
            let progressPercentage = Math.round(progress.loaded / progress.total * 100);
            setProgress(progressPercentage)
        })
    }
    // const ReactS3Client = new S3(config);
    const uploadFile = (file, name) => {
        setShowWait(true)
        upProg(name,file)
        // ReactS3Client.uploadFile(file, "i2global-" + Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + "-" + name)
        //     .then((data) => {
        //         setTimeout(() => { setShowWait(false) }, 1000);
        //     })
    };
    useEffect(() => {
        window.addEventListener('load', () => {
           // startButton = document.querySelector('.start-recording');
            //stopButton = document.querySelector('.stop-recording');
            downloadButton = document.querySelector('.download-video');
            recordedVideo = document.querySelector('.recorded-video');
            // startButton.addEventListener('click', setupStream);
            // stopButton.addEventListener('click', stopRecording);
            // if (RecordStart) {
            //     setTimeout(() => {
            //         setupStream();
            //         stBtn.current.disabled =false;
            //         stopBtn.current.disabled = true;
            //     }, 1000);
            // }
        })
        stBtn.current.disabled =false;
        stopBtn.current.disabled = true;
        // setupStream();
    }, [])
    function stopRecording() {
        recorder?.stop();
        stBtn.current.disabled =false;
        stopBtn.current.disabled = true;
        // startButton.disabled = false;
        // stopButton.disabled = true;
    }
    function setupStream() {
           // alert('setup')
        navigator?.mediaDevices?.getDisplayMedia({ video: true, audio: true })
            .then(function (screenStream) {
                stream = screenStream;
                navigator?.mediaDevices?.getUserMedia({
                    audio: { echoCancellation: true, noiseSuppression: true, sampleRate: 44100, },
                }).then(function (micStream) {
                    var composedStream = new MediaStream();
                    screenStream?.getVideoTracks()?.forEach(function (videoTrack) {
                        composedStream.addTrack(videoTrack);
                    });
                    var context = new AudioContext();
                    var audioDestinationNode = context.createMediaStreamDestination();
                    if (screenStream && screenStream.getAudioTracks().length > 0) {
                        //get the audio from the screen stream
                        const systemSource = context.createMediaStreamSource(screenStream);
                        //set it's volume (from 0.1 to 1.0)
                        const systemGain = context.createGain();
                        systemGain.gain.value = 1.0;
                        //add it to the destination
                        systemSource.connect(systemGain).connect(audioDestinationNode);
                    }
                    if (micStream && micStream.getAudioTracks().length > 0) {
                        //get the audio from the microphone stream
                        const micSource = context.createMediaStreamSource(micStream);
                        //set it's volume
                        const micGain = context.createGain();
                        micGain.gain.value = 1.0;
                        //add it to the destination
                        micSource.connect(micGain).connect(audioDestinationNode);
                    }
                    audioDestinationNode.stream.getAudioTracks().forEach(function (audioTrack) {
                        composedStream.addTrack(audioTrack);
                    });
                    startRecording(composedStream)
                }).catch(function (err) {
                    console.log(err);
                    //alert("You need a microphone to run the demo");
                });
            }).catch(function (err) {
                console.log(err);
               // alert("You need to share your screen to run the demo");
            });
    }
    function handleDataAvailable(e) {
        chunks.push(e.data);
    }
    function handleStop(e) {
        blob = new Blob(chunks, { type: chunks[0]?.type });
        chunks = [];
        // downloadButton.href = URL.createObjectURL(blob);
        // downloadButton.download = 'video.mp4';
        // downloadButton.disabled = false;
        // downloadButton.click();
        // setblobUrl(URL.createObjectURL(blob))
        upProg( `${data.meeting_link}.webm`,new File([blob], `${data.meeting_link}.webm`))
        // recordedVideo.src = URL.createObjectURL(blob);
        // recordedVideo.load();
       
        // stream.getTracks().forEach((track) => track.stop());
        // audio.getTracks().forEach((track) => track.stop());
    }
    async function startRecording(s) {
        recorder = new MediaRecorder(s);
        recorder.ondataavailable = handleDataAvailable;
        recorder.onstop = handleStop;
        recorder.start();
        stBtn.current.disabled =true;
        stopBtn.current.disabled = false;
        // startButton.disabled = true;
        // stopButton.disabled = false;
        // recorder.addEventListener('stop', function () {
        //     handleStop();
        // })
    }
    // useEffect(()=>{
    //     if(classEnd){
    //       //  alert('class end')
    //      // handleStop('e')
    //     }
    // },[classEnd])   
    return (
        <>
            {showWait ? <WaitingScreen  progress={progress}/> :
                <>
                    <div class="record_btn_container">
                        <button class="start-recording" ref={stBtn} onClick={()=>{setupStream()}}>
                            <FilterCenterFocusIcon /> Record Screen
                        </button>
                        <button class="stop-recording" ref={stopBtn}  onClick={()=>{stopRecording()}}>
                            <StopIcon />   Stop Recording
                        </button>
                    </div>
                    <video src="" class="recorded-video bg-black w-full h-auto mb-8" style={{ display: 'none' }}></video>
                    <div class="flex flex-wrap -mx-4">
                        <a class="download-video " style={{ display: 'none' }} disabled>
                            Download
                        </a>
                    </div>
                </>
            }
        </>
    )
}