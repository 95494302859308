import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { axiosToken } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";


export default function ManageAcademyInfo({ openDialogProp, onClose, onCancel }) {
    const SchoolInfoSchema = Yup.object({
        academy_name: Yup.string().required(constant.addSchoolInfo.academy_name).trim(),
        /*  .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), */
        // academy_info: Yup.string().required(constant.addSchoolInfo.academy_info).trim(),
        /*  .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "), */
        academy_board_master: Yup.string().required(constant.addSchoolInfo.academy_board_master).trim(),
        academy_branch_master: Yup.string().required(constant.addSchoolInfo.academy_branch_master).trim(),
        /*  branch_location_name:Yup.string().required(constant.addSchoolInfo.branch_location_name),  */
    })
    const [open, setOpen] = useState(false);
    const [state, setState] = useState([]);
    const [country, setCountry] = useState([]);
    const [branch, setBranch] = useState([])
    const [board, setBoard] = useState([]);
    const [academic_branch, setAcademic_branch] = useState([]);
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    useEffect(() => {
        axiosToken.get("/admin/academymasterinfo ").then((res) => {
            setState(res.data.state)
            setCountry(res.data.country)
            setBoard(res.data.board)
            setBranch(res.data.branch)

        })
    }, [])
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
    const initialValues = {
        academy_name: '',
        academy_info: '',
        branch_location_name: '',
        /*  academy_branch_city:'',
         state:'',
         country:'', */
        academy_board_master: '',
        academy_branch_master: ''
    }
    return (
        <div>
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New Institution</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={SchoolInfoSchema}
                                onSubmit={(values) => {
                                    axiosToken.post('/admin/academymasterinfo', values)
                                        .then((res) => {
                                            if (res.data.status) {
                                                toast.success(res.data.message, {
                                                    position: toast.POSITION.TOP_RIGHT,
                                                });
                                                handleClose();
                                            }
                                        })
                                        .catch((err) => {
                                            toast.error(err.response.data.detail, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                        })
                                }}
                            >
                                {({ errors, handleBlur, handleChange, values, touched, handleSubmit }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="academy_name"
                                                                label="Academy Name"
                                                                error={Boolean(touched.academy_name && errors.academy_name)}
                                                                helperText={touched.academy_name && errors.academy_name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.academy_name}
                                                                variant="outlined" />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="academy_info"
                                                                label="Academy Info"
                                                                /*   error={Boolean(touched.academy_info && errors.academy_info)}
                                                                  helperText={touched.academy_info && errors.academy_info} */
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.academy_info}
                                                                variant="outlined" />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>

                                                            <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select Branch
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='academy_branch_master'
                                                                    label="Select Branch"
                                                                    error={Boolean(touched.academy_branch_master && errors.academy_branch_master)}
                                                                    helperText={touched.academy_branch_master && errors.academy_branch_master}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.academy_branch_master}
                                                                >
                                                                    {branch.map((e, key) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={key}
                                                                                value={e.id}
                                                                            >
                                                                                {e.branch_location_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                            {errors.academy_branch_master ? <p style={{ color: '#d32f2f', fontSize: '12px', margin: 0, textAlign: 'left', marginLeft: '10px' }}>Please Select Any Branch</p> : null}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select Board
                                                                </InputLabel>
                                                                <Select
                                                                    labelId="city-label"
                                                                    id="ciry-select"
                                                                    name='academy_board_master'
                                                                    label="Select Board"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.academy_board_master && errors.academy_board_master)}
                                                                    helperText={touched.academy_board_master && errors.academy_board_master}
                                                                >
                                                                    {board.map((e, key) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={key}
                                                                                value={e.id}
                                                                            >
                                                                                {e.board_name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                            {errors.academy_board_master ? <p style={{ color: '#d32f2f', fontSize: '12px', margin: 0, textAlign: 'left', marginLeft: '10px' }}>Please Select Any Board</p> : null}

                                                        </div>
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}
                                                        onClick={() => { handleSubmit(); }}
                                                    >
                                                        <h6 >Add Academy</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5"
                                                        onClick={() => { onCancel(false); }}
                                                    >
                                                        <h6 >Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
}