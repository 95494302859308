import { useEffect, useState, useRef } from "react";
import '../ClassSchedule/ClassSchedule.css'
import TextField from "@mui/material/TextField";
import DatePicker from "react-multi-date-picker";
import Images from '../../../../../constant/Images';
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment-timezone";

export default function ClassSchedule({ handleBlur, handleChange,setFieldValue, isedit,data }) {
var todayDate = new Date();
const yyyy = todayDate.getFullYear();
let mm = todayDate.getMonth() + 1; // Months start at 0!
let dd = todayDate.getDate();
let hh = todayDate.getHours();
let mi = todayDate.getMinutes();
if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;
var todayDate = dd + '/' + mm + '/' + yyyy;
// var initalTime = hh+mi;
    const [dates, setDates] = useState( isedit ? data?.class_date||[] : []);
    // const [startTime,setStartTime] = useState();
    const startTime = data?.class_start_on && moment(data.class_start_on, "hh:mm A").toDate()
    const endTime = data?.class_ends_on && moment(data.class_ends_on, "hh:mm A").toDate()
    // const [endTime,setEndTime] = upseState();
    // const [validEndTime,setValidEndTime] = useState(initalTime);
    // const [validStartTime,setValidStartTime] = useState(initalTime);
    // useEffect(()=>{alert(date)},[])
    useEffect(() => {
        const formattedDates = [...dates].map(date => {
            let formattedDate = date
            if (typeof date === 'string'){
                formattedDate = moment(date).format('DD/MM/YYYY')
            }
            if(typeof date === 'object'){
                formattedDate = date.format('DD/MM/YYYY')
            }

            return formattedDate
        })
        // const formattedDates = [...dates].map(date => moment(date).format('DD/MM/YYYY'))
        setFieldValue('class_date', formattedDates)
        
    }, [dates])

   const datePickerRef = useRef();
   const startTimeRef = useRef();
   const EndTimeRef = useRef()
    return (
        <div className='form_container'>
            <div className="form_field position-relative">
                <div className="col-md-12">
                    <div className="course_title form_field position-relative">
                        <TextField
                            id="outlined-basic"
                            label="Class Title"
                            variant="outlined"
                            onBlur={(e) => {
                                setFieldValue("class_name", e.target.value);
                            }}
                            onChange={(e) => setFieldValue("class_name", e.target.value)}
                            name='class_name'
                            value={data?.class_name}
                        />

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="date_picker_multiple">
                            <DatePicker
                                value={dates}
                                onChange={setDates}
                                format="YYYY-MM-DD"
                                multiple
                                ref={datePickerRef} 
                                minDate={new Date()}
                            />
                            <span className="picker__icon__cont"> <img src={Images.calendar} className='picker__icon' 
                            onClick={()=> datePickerRef.current.openCalendar()}
                            /></span>
                            <span className="lable__flot_custom">Select Dates</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="date_picker_multiple">
                            <DatePicker value={startTime} disableDayPicker format="hh:mm A"
                                minDate={new Date()}
                                ref={startTimeRef}
                                plugins={[<TimePicker /> ]} 
                                onChange={(e)=> {   
                                    // setValidStartTime(e.format('hhmm'));
                                    setFieldValue("class_start_on", e.format());
                                }}
                            />
                            <span className="picker__icon__cont"><img src={Images.clock} className='picker__icon'
                            onClick={()=> startTimeRef.current.openCalendar()}
                            /></span>
                            <span className="lable__flot_custom">Start Time</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="date_picker_multiple">
                            <DatePicker
                                value={endTime}
                                //onChange={dateObjects => { setValue(dateObjects) }}
                                disableDayPicker
                                format="hh:mm A"
                                ref={EndTimeRef}
                                minTime={new Date()}
                                plugins={[ <TimePicker />]} 
                                onChange={(e)=> {
                                    setFieldValue("class_ends_on", e.format());
                                    // setValidEndTime(e.format('hhmm'));
                                    // if(parseInt((validStartTime)) >= parseInt( e.format('hhmm'))){
                                    //     console.log(parseInt((validStartTime)),parseInt( e.format('hhmm')))
                                    // }
                                }}
                            />
                            <span className="picker__icon__cont"><img src={Images.clock} className='picker__icon'
                            onClick={()=> EndTimeRef.current.openCalendar()} /></span> 
                            <span className="lable__flot_custom">End Time</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
