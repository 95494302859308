import Header from '../../../components/Header/Header'
import Sidebar from '../../../components/Sidebar/Sidebar'
import ManageCourseList from './ManageCourseList';

export default function ManageCourses() {
  return (
    <div>
        <Header/>
        <Sidebar />
        <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <ManageCourseList />
          </div>
        </div>
      </div>
    </div>
  )
}
