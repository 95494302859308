import { useEffect, useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField,Select,MenuItem,FormControl,InputLabel } from "@mui/material/";
import { Formik, Form } from "formik";
import { axiosToken,s3config } from '../../../../services/api';
import { toast } from "react-toastify";
import S3 from "react-aws-s3";
import Images from '../../../../constant/Images';

export default function AddAcademyImages({ openDialogProp, onClose }) {

    const initialValues = {
        id:'',
        name:'',
        blob_type:'',
        academy_master_info:'',
    }

    const [open, setOpen] = useState(false);
    const blob_img = useRef(null);
    const [grade, setGrade] = useState([]);
    const handleClose = () => {
        onClose(false)
        setOpen(false);
    };
    let config = {
        ...s3config,
        dirName: "category/add-category",
      };
    useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])
  

    const ReactS3Client = new S3(config);
const uploadFile = (file, name, setFieldValue, field_name) => {
  ReactS3Client.uploadFile(file, 'i2global-' +
  Math.floor(new Date().getTime() + new Date().getSeconds() / 2)+'-'+name)
    .then((data) => {setFieldValue(field_name, data.key); })
    .catch((err) => console.log(err));
};

function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }

    return (
        <div >
            <Dialog
                open={open}
                fullWidth={'md'}
                maxWidth={'md'}
                keepMounted={false}
                aria-describedby="alert-dialog-slide-description"
                className='assignment__dialog add_new_std'
            >
                <DialogTitle>Add New City</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div className="add_std_form">
                        <Formik
                                initialValues={initialValues}
                                //validationSchema={BoardSchema}
                                onSubmit={(values) => {
                                   axiosToken.post('/admin/academyimages',values)
                                   .then((res)=>{
                                    if(res.data.status){
                                       toast.success(res.data.message, {
                                           position: toast.POSITION.TOP_RIGHT,
                                         });
                                         handleClose();
                                    }
                                  })
                                  .catch((err)=>{
                                   toast.error(err.response.data.detail, {
                                       position: toast.POSITION.TOP_RIGHT,
                                     });
                                  })
                                }}
                            >
{({ errors, handleBlur, handleChange, values, touched,handleSubmit,setFieldValue }) => (
                                    <>
                                        <div className="row no-gutters">
                                            <Form>
                                                <div className='container-fluid'>
                                                    <div className='row mt-5'>
                                                        <div className='col-md-6'>
                                                            <TextField
                                                                className="fieldtext"
                                                                type="text"
                                                                name="name"
                                                                label="Name"
                                                                 error={Boolean(touched.name && errors.name)}
                                                                helperText={touched.name && errors.name} 
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.name}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className='col-md-6'>
                                                        <FormControl fullWidth>
                                                                <InputLabel id="city-label">
                                                                    Select Academy Info
                                                                </InputLabel>
                                                                <Select

                                                                className="fieldtext"
                                                                type="text"
                                                                name="academy_master_info"
                                                                label="City"
                                                                 error={Boolean(touched.academy_master_info && errors.academy_master_info)}
                                                                helperText={touched.academy_master_info && errors.academy_master_info} 
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.academy_master_info}
                                                                variant="outlined"
                                                            >
                                                                 {grade.map((e, key) => {
                                                                        return (
                                                                            <MenuItem
                                                                                key={key}
                                                                                value={e.id}
                                                                            >
                                                                                {e.academy_info}
                                                                            </MenuItem>
                                                                        );
                                                                    })} 
                                                                </Select>
                                                            </FormControl>
                                                        </div>

                                                      </div>

                                                      <div className='row mt-5'>
                                                      <div className="col-md-6 form_field position-relative">
                    <TextField
                      id="outlined-basic"
                      label="Upload Document"
                      variant="outlined"
                      error={Boolean(touched.blob && errors.blob)}
                      helperText={touched.blob && errors.blob} 
                      onBlur={handleBlur}
                      name="blob"
                      value={values.blob}
                    />
                    <span
                      className="upload__icones"
                      onClick={() => {
                        blob_img.current.click();
                      }}
                    >
                      <img src={Images.upload_icon} alt="upload" />
                    </span>
                    <input
                      type="file"
                      multiple
                      id="file"
                      ref={blob_img}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (
                          checkValidFile(
                            e.target.files[0],
                             [".jpeg", ".jpg", "png", ".pdf", ".doc", ".txt"],
                            20000000
                          )
                        ) {
                          setFieldValue("interest_image", e.target.files[0].name);
                          uploadFile(
                            e.target.files[0],
                            e.target.files[0].name,
                            setFieldValue,
                            "interest_image"
                          );
                        } else {
                          toast.error("Please Select file.",{position: toast.POSITION.TOP_RIGHT,});
                        }
                      }}
                    />
                  </div>
                                                      </div>
                                                      </div>
                                                <DialogActions>
                                                    <div
                                                        className="gender_btn btn_active mt-5"
                                                        style={{ maxWidth: '150px' }}

                                                    >
                                                        <h6 onClick={() => { handleSubmit() }}>Add Images</h6>
                                                    </div>
                                                    <div className="gender_btn mt-5" >
                                                        <h6 onClick={() => { handleClose(false) }}>Cancel</h6>
                                                    </div>
                                                </DialogActions>
                                            </Form>
                                        </div>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div>
    )
                       

}