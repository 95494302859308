import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
//import '../../AdminPages/ManageTeachers/ManageTeachers.css';
import ManageCategoryListTable from './components/ManageCategoryListTable';


export default function ManageCategory() {
  return (
    <div>
      <Header />
      <Sidebar />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <ManageCategoryListTable />
          </div>
        </div>
      </div>
    </div>
  )
}