import React, { useEffect, useState } from "react";
import "../Courses/Courses.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";

export default function Quizes() {
    const navigate = useNavigate();

  useEffect(() => {
    getQuizData("1");
  }, []);

  const [value, setValue] = React.useState("1");
  const [InprogressData, setProgressData] = useState([]);
  const [publishedData, setPublishedData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    getQuizData(newValue);
  };

  const getQuizData = (id) => {
    // axiosToken
    //   .get("tutor/tutorcreatequiz?status_id=" + id)
    //   .then((res) => {
    //     id == "0"
    //       ? setProgressData(res.data)
    //       : setPublishedData(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  return (
    <>
      <div className="list_box courses">
        <TabContext value={value}>
          <div className="list_box_header">
            <h4>Assessments</h4>
            <TabList   TabIndicatorProps={{ style: { backgroundColor: "black", } }} onChange={handleChange}>
            <Tab label="Published" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}}value="1" />
              <Tab label="Inprogress" style={{textTransform:'capitalize',fontSize:'18px',marginTop:'-2px'}} value="0" /> 
            </TabList>
          </div>
          <div className="tab__body">
            <TabPanel value="0">
              <div className="tabs_table">
                {InprogressData ? (
                  InprogressData.map((data, i) => (
                    <div className="table_list" key={i}>
                     {/*  <img
                        src={data.course_image}
                        className="course_img"
                        alt="course_img"
                      /> */}
                      <div className="course__info">
                         <h4>{data.course_name}</h4>
                        
                      </div>
                      <div className="duration">
                        <span>{data.duration} </span>
                        <span>{data.quiz_name}</span>
                      </div>
                   {/*    <div className="progress_container">
                        <div className="progress_box">
                          <span className="progress_bar"></span>
                        </div>
                      </div> */}
                     {/*  <div className="view_schedule">
                        <ScheduleDialog data={data} />
                      </div> */}
                
                    </div>
                  ))
                ) : (
                  <h6>No data found</h6>
                )}
              </div>
            </TabPanel>
            <TabPanel value="1">
              {publishedData ? (
                publishedData.map((data, i) => (
                  <div className="table_list" key={i}>    
                    <div className="course__info">  <h6 >{data.course_name}</h6></div>
                    <div className="course__info">
                      <h4>{data.quiz_name}</h4>
                    {/*   <span>{data.date}</span> */}
                    </div>
                    <div className="duration">
                      <span>{data.duration} min</span>
                    </div>
                    <div className="progress">
                      <LinearProgress value={20} />
                    </div>
                 
                  </div>
                ))
              ) : (
                <h6>No data found</h6>
              )}
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </>
  );

}