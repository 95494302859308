import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dateFormat } from '../../../../helpers/utils';


export default function StudentAssignmentList({data}) {
    const [rows, setRows] = useState(data);
    return (
        <div>
           
            <h6 style={{textAlign:'left',marginTop:'10px',fontFamily:'sans-serif',color:'#0b0b0b',fontWeight:700}}>Assignment Details</h6>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Assignment Title</TableCell>
                            <TableCell>Course</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Description</TableCell>
                          
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row?.assignment_name}</TableCell>
                                <TableCell>{row?.course_name}</TableCell>
                                <TableCell>{dateFormat(row?.assignment_start_date)}</TableCell>
                                <TableCell>{dateFormat(row?.assignment_end_date)}</TableCell>
                                <TableCell> {row.status == 2 ?
                                        <span className='status pending_assignment'>Pending</span>
                                        : row.status == 3 ?
                                            <span className='status redo_assignment'>Redo</span>
                                            : row.status == 4 ?
                                                <span className='status completed_assingnment'>Completed</span>
                                                : null
                                    }</TableCell>
                                <TableCell>{row?.assignment_description}</TableCell>
                               
                                
                                
                            </TableRow>
                        ))}
                       
                    </TableBody>
                   
                </Table>
                {data == ''? <h5 style={{textAlign:'center',width:'100%'}}>No Data Found</h5>:null }
            </TableContainer>
            {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={total}
                    rowsPerPage={rowPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
        </div>
    )
}
