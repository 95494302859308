import { useEffect, useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material/";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { axiosToken, s3config } from '../../../../services/api';
import constant from '../../../../constant/Validation';
import { toast } from "react-toastify";
import S3 from "react-aws-s3";
import Images from '../../../../constant/Images';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


export default function AddCategory({ openDialogProp, onClose, onCancel }) {
  const CategorySchema = Yup.object({
    tags: Yup.string().required(constant.addnewCategory.tags).trim()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    interest_image: Yup.string().required(constant.addnewCategory.interest_image)
  })

  const [open, setOpen] = useState(false);
  const blob_img = useRef(null);
  const handleClose = () => {
    onClose(false)
    setOpen(false);
  };
  let config = {  ...s3config,  dirName: "category/add-category",};
  const initialValues = { tags: '',  interest_image: ''}

  const ReactS3Client = new S3(config);
  const uploadFile = (file, name, setFieldValue, field_name) => {
    ReactS3Client.uploadFile(file, 'i2global-' +
      Math.floor(new Date().getTime() + new Date().getSeconds() / 2) + '-' + name)
      .then((data) => { setFieldValue(field_name, data.key); })
      .catch((err) => console.log(err));
  };

  function checkValidFile(inputID, exts, size) {
    if (inputID.size <= size) {
      return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
        inputID.name
      );
    }
  }

  useEffect(() => { setOpen(openDialogProp) }, [openDialogProp])

  return (
    <div >
      <Dialog
        open={open}
        fullWidth={'md'}
        maxWidth={'md'}
        keepMounted={false}
        aria-describedby="alert-dialog-slide-description"
        className='assignment__dialog add_new_std'
      >
        <DialogTitle>Add New Interest</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="add_std_form">
              <Formik
                initialValues={initialValues}
                validationSchema={CategorySchema}
                onSubmit={(values) => {
                  axiosToken.post('/admin/intersettags', values)
                    .then((res) => {
                      if (res.data.status) {
                        toast.success(res.data.message, {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        handleClose();
                      }
                    })
                    .catch((err) => {
                      toast.error(err.response.data.detail, {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    })
                }}
              >
                {({ errors, handleChange, handleBlur, setFieldValue, values, touched, handleSubmit }) => (
                  <>
                    <div className="row no-gutters">
                      <Form>
                        <div className='container-fluid'>
                          <div className='row mt-5'>
                            <div className='col-md-6'>
                              <TextField
                                className="fieldtext"
                                type="text"
                                name="tags"
                                label="Tags"
                                error={Boolean(touched.tags && errors.tags)}
                                helperText={touched.tags && errors.tags}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.tags}
                                variant="outlined"
                              />
                            </div>

                            <div className="col-md-6 form_field position-relative">
                              <TextField id="outlined-basic" label="Upload Document" variant="outlined"
                                error={Boolean(touched.interest_image && errors.interest_image)}
                                helperText={touched.interest_image && errors.interest_image}
                                onBlur={handleBlur}
                                name="interest_image"
                                value={values.interest_image}
                              />
                              <span className="upload__icones" onClick={() => { blob_img.current.click(); }}>
                                <img src={Images.upload_icon} alt="upload" />
                              </span>
                              <div className={"preview " + (values.interest_image ? "d-block" : "d-none")
                              }>
                                <Tooltip title={'Remove'}
                                  onClick={() => {setFieldValue('interest_image','');blob_img.current.value='' }}
                                  style={{ position: 'absolute', right: '-6px', top: '43px', background: 'snow', objectFit: 'cover' }}>
                                  <IconButton><CloseIcon /> </IconButton>
                                </Tooltip>
                                <img id='output' style={{ width: '100px', height: '100px', borderRadius: '20px', marginTop: '20px', marginLeft: 'auto', display: 'block' }} />
                              </div>
                              <input type="file" multiple id="file" ref={blob_img} style={{ display: "none" }}
                                onChange={(e) => {
                                  if (checkValidFile(e.target.files[0], [".jpeg", ".jpg", "png"], 200000)) {
                                    setFieldValue("interest_image", e.target.files[0].name);
                                    uploadFile(e.target.files[0], e.target.files[0].name, setFieldValue, "interest_image");
                                    var output = document.getElementById("output");
                                    output.src = URL.createObjectURL(e.target.files[0]);
                                    output.onload = function () {
                                      URL.revokeObjectURL(output.src); // free memory
                                    };
                                  } else {
                                    toast.error("Please Select Valid file.", { position: toast.POSITION.TOP_RIGHT, });
                                  }
                                }}
                              />
                            </div>

                          </div>
                        </div>
                        <DialogActions>
                          <div
                            className="gender_btn btn_active mt-5"
                            style={{ maxWidth: '150px' }}
                            onClick={() => { handleSubmit() }}
                          >
                            <h6 >Add Category</h6>
                          </div>
                          <div className="gender_btn mt-5" onClick={() => { onCancel(false) }} >
                            <h6 >Cancel</h6>
                          </div>
                        </DialogActions>
                      </Form>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </DialogContentText>
        </DialogContent>

      </Dialog>
    </div>
  )
}
